// src/context/UserCacheContext.js
import { createContext, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { collection, getDocs, where, query, getDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import UserContext from './UserContext';

const UserCacheContext = createContext();

export const UserCacheProvider = ({ children }) => {
  const [cache, setCache] = useState(new Map());
  const userContext = useContext(UserContext);
  const user = userContext?.user;

  // Limpiar cache cuando el usuario cambie
  useEffect(() => {
    if (!user) {
      setCache(new Map());
    }
  }, [user]);

  const getUsers = useCallback(async (userIds) => {
    const uniqueIds = [...new Set(userIds)];
    const usersToFetch = uniqueIds.filter(id => !cache.has(id));
    let updatedCache = new Map(cache);

    if (usersToFetch.length > 0) {
      try {
        const q = query(collection(db, 'users'), where('uid', 'in', usersToFetch));
        const snapshot = await getDocs(q);

        // Obtener el estado de siguiendo del usuario actual
        let siguiendoList = [];
        if (user?.uid) {
          const currentUserDoc = await getDoc(doc(db, 'users', user.uid));
          siguiendoList = currentUserDoc.exists() ? currentUserDoc.data().siguiendo || [] : [];
        }

        snapshot.docs.forEach(docSnap => {
          const userData = docSnap.data();
          updatedCache.set(docSnap.id, { 
            ...userData, 
            uid: docSnap.id,
            isFollowing: siguiendoList.includes(docSnap.id)
          });
        });

        setCache(updatedCache);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }

    return uniqueIds.map(id => updatedCache.get(id) || { uid: id });
  }, [cache, user?.uid]);

  const updateCache = useCallback((userId, updater) => {
    setCache(prev => {
      const newCache = new Map(prev);
      const currentData = newCache.get(userId) || {};
      const updatedData = updater(currentData);
      newCache.set(userId, updatedData);
      return newCache;
    });
  }, []);

  const getCachedUser = useCallback((userId) => {
    return cache.get(userId) || null;
  }, [cache]);

  const clearCache = useCallback(() => {
    setCache(new Map());
  }, []);

  const value = useMemo(() => ({
    getUsers,
    updateCache,
    getCachedUser,
    clearCache,
    cache
  }), [getUsers, updateCache, getCachedUser, clearCache, cache]);

  return (
    <UserCacheContext.Provider value={value}>
      {children}
    </UserCacheContext.Provider>
  );
};

export const useUserCache = () => {
  const context = useContext(UserCacheContext);
  if (!context) {
    throw new Error('useUserCache must be used within a UserCacheProvider');
  }
  return context;
};

export default UserCacheContext;