// src/components/Notifications/NotificationNavigation.js
/**
 * Maneja la navegación basada en diferentes tipos de notificaciones
 * @param {Object} notification - El objeto de notificación
 * @returns {string} La ruta a la que navegar
 */
export const getNotificationPath = (notification) => {
  console.log("Obteniendo ruta para notificación:", notification);
  
  // Si no hay notificación, retornar vacío
  if (!notification) return '';

  // Extraer datos importantes
  const { type, extraData, serviceId } = notification;
  
  // Manejar el formato de navegación estructurada
  if (extraData?.navigation) {
    const { navigation } = extraData;
    console.log("Usando datos de navegación estructurada:", navigation);
    
    switch(navigation.type) {
      case 'profile':
        if (navigation.data?.userId) {
          return `/profile/${navigation.data.userId}`;
        }
        break;
        
      case 'post':
        if (navigation.data?.postId) {
          return `/post/${navigation.data.postId}`;
        }
        break;
        
      case 'comment':
        if (navigation.data?.postId) {
          return `/post/${navigation.data.postId}?highlight=comment_${navigation.data.commentId}`;
        }
        break;
        
      case 'message':
        if (navigation.data?.conversationId) {
          return `/messages/${navigation.data.conversationId}`;
        } else if (navigation.data?.userId) {
          return `/messages/new/${navigation.data.userId}`;
        }
        break;
        
      case 'service':
        if (navigation.data?.requestId) {
          return `/workbench/request/${navigation.data.requestId}`;
        } else if (navigation.data?.serviceId) {
          return `/service/${navigation.data.serviceId}`;
        }
        return '/workbench';
        
      case 'withdrawal':
        if (navigation.data?.withdrawalId) {
          return `/admin/withdrawals/${navigation.data.withdrawalId}`;
        }
        return '/admin/withdrawals';
    }
  }
  
  // Formato antiguo (fallback)
  console.log("Usando formato de navegación antiguo para tipo:", type);
  
  // Función helper para manejar rutas de servicio
  const getServicePath = (extraData, serviceId) => {
    if (extraData?.requestId) {
      return `/workbench/request/${extraData.requestId}`;
    } else if (serviceId || extraData?.serviceId) {
      return `/service/${serviceId || extraData.serviceId}`;
    }
    return '/workbench';
  };

  // Función helper para manejar rutas de retiros
  const getWithdrawalPath = (extraData) => {
    if (extraData?.withdrawalId) {
      return `/admin/withdrawals/${extraData.withdrawalId}`;
    }
    return '/admin/withdrawals';
  };

  // Manejar tipos específicos de notificaciones
  switch (type) {
    // Notificaciones relacionadas con servicios
    case 'service_request':
    case 'service_accepted':
    case 'service_rejected':
    case 'service_completed':
      return getServicePath(extraData, serviceId);
    
    case 'service_delivered':
      if (extraData?.requestId) {
        return `/service-details/${extraData.requestId}`;
      }
      return getServicePath(extraData, serviceId);
      
    // Notificaciones relacionadas con retiros de dinero
    case 'withdrawal_requested':
    case 'withdrawal_processed':
    case 'withdrawal_rejected':
      return getWithdrawalPath(extraData);
      
    // Notificaciones relacionadas con posts
    case 'like':
    case 'comment':
      if (extraData?.postId) {
        return `/post/${extraData.postId}`;
      }
      break;
      
    case 'like_comment':
      if (extraData?.postId) {
        return `/post/${extraData.postId}?highlight=comment_${extraData.commentId}`;
      }
      break;
    
    // Notificaciones de seguimiento
    case 'follow':
      if (extraData?.userId) {
        return `/profile/${extraData.userId}`;
      }
      break;
      
    // Notificaciones de mensajes
    case 'message':
      if (extraData?.conversationId) {
        return `/messages/${extraData.conversationId}`;
      } else if (extraData?.userId) {
        return `/messages/new/${extraData.userId}`;
      }
      break;
  }

  return '';
};

/**
 * Navega a la ruta especificada usando window.history o fallback
 * @param {string} path - La ruta a la que navegar
 */
export const navigateTo = (path) => {
  if (!path) return;
  
  console.log("Navegando a:", path);
  
  // Usar History API si está disponible
  if (window.history && window.history.pushState) {
    window.history.pushState({}, '', path);
    // Disparar evento para que otros componentes detecten el cambio
    window.dispatchEvent(new Event('popstate'));
  } else {
    // Fallback para navegadores antiguos
    window.location.href = path;
  }
};

/**
 * Función para procesar el clic en una notificación
 * @param {Object} notification - El objeto de notificación
 * @param {Function} onClose - Función para cerrar el panel de notificaciones
 */
export const handleNotificationNavigation = (notification, onClose) => {
  console.log("Manejando navegación de notificación:", notification);
  
  // Cerrar panel de notificaciones
  if (onClose) onClose();
  
  // Obtener la ruta y navegar
  const path = getNotificationPath(notification);
  
  if (path) {
    navigateTo(path);
  } else {
    console.warn("No se pudo determinar una ruta para la notificación");
  }
};