/**
 * Constantes para el sistema de pagos
 */

// Mensajes de error
export const ERROR_MESSAGES = {
  VALIDATION_ERROR: 'Por favor, ingresa un monto válido mayor a 0.',
  UNAUTHORIZED: 'No estás autenticado. Por favor, inicia sesión nuevamente.',
  GENERIC_ERROR: 'Error al procesar el pago. Inténtalo de nuevo más tarde.',
  CONNECTION_ERROR: 'Error de conexión. Verifica tu conexión a internet e inténtalo de nuevo.',
  PAYMENT_CREATION_ERROR: 'Error al crear el pago. Inténtalo de nuevo más tarde.',
  PAYMENT_PROVIDER_ERROR: 'Error con el proveedor de pagos. Inténtalo de nuevo más tarde.',
  TERMS_ERROR: 'Debes aceptar los términos y condiciones para continuar.',
  ACCOUNT_LINKING_ERROR: 'Error al vincular la cuenta. Inténtalo de nuevo más tarde.',
  ACCOUNT_UNLINKING_ERROR: 'Error al desvincular la cuenta. Inténtalo de nuevo más tarde.',
  PAYMENT_INFO_ERROR: 'Error al obtener información de pago. Inténtalo de nuevo más tarde.',
  BALANCE_UPDATE_ERROR: 'Error al actualizar el balance. Inténtalo de nuevo más tarde.',
  PAYMENT_VERIFICATION_ERROR: 'Error al verificar el pago. Inténtalo de nuevo más tarde.',
  PAYMENT_CALLBACK_ERROR: 'Error al procesar la respuesta del pago. Inténtalo de nuevo más tarde.',
  CONFIG_ERROR: 'Error de configuración: Variables de entorno faltantes',
  ENV_VARS_NOT_LOADED: 'Error de configuración: Variables de entorno no cargadas',
  USER_INFO_NOT_LOADED: 'No se ha cargado la información del usuario.',
  INVALID_SESSION: 'Sesión no válida',
  INVALID_PARAMS: 'Parámetros inválidos',
  INVALID_STATE: 'Estado inválido',
  AUTH_REQUIRED: 'No hay usuario autenticado',
  CURRENCY_MISMATCH: 'La moneda seleccionada no es compatible con este método de pago.'
};

// Textos de carga
export const LOADING_TEXTS = {
  PROCESSING: 'Procesando...',
  CONNECTING: 'Conectando...',
  DISCONNECTING: 'Desconectando...',
  VERIFYING: 'Verificando...',
  REDIRECTING: 'Redirigiendo...',
  UPDATING: 'Actualizando...',
  LOADING: 'Cargando...',
  CONNECTING_MERCADOPAGO: 'Conectando con MercadoPago...',
  UNLINKING_MERCADOPAGO: 'Desvinculando MercadoPago...',
  CONNECTING_PAYPAL: 'Conectando con PayPal...',
  UNLINKING_PAYPAL: 'Desvinculando PayPal...',
  LINKING_MERCADOPAGO: 'Procesando vinculación con Mercado Pago...',
  LINKING_PAYPAL: 'Procesando vinculación con PayPal...'
};

// Endpoints de la API
export const API_ENDPOINTS = {
  CREATE_BALANCE_PAYMENT: '/payments/create',
  GET_PAYMENT_INFO: '/payments',
  LINK_MERCADOPAGO: '/users/link-mercadopago',
  UNLINK_MERCADOPAGO: '/users/unlink-mercadopago',
  LINK_PAYPAL: '/users/link-paypal',
  UNLINK_PAYPAL: '/users/unlink-paypal',
  UPDATE_BALANCE: '/users/update-balance',
  VERIFY_PAYMENT: '/payments/verify',
};

// Descripciones de pagos
export const PAYMENT_DESCRIPTIONS = {
  BALANCE_RECHARGE: 'Recarga de saldo',
  MEMBERSHIP_PAYMENT: 'Pago de membresía',
  SERVICE_PAYMENT: 'Pago de servicio',
  COMMISSION_PAYMENT: 'Pago de comisión',
  WITHDRAWAL: 'Retiro de fondos',
};

// Monedas soportadas
export const CURRENCIES = {
  USD: {
    code: 'USD',
    symbol: '$',
    name: 'Dólar estadounidense',
    locale: 'en-US'
  },
  ARS: {
    code: 'ARS',
    symbol: '$',
    name: 'Peso argentino',
    locale: 'es-AR'
  }
};

// Configuración de proveedores de pago por moneda
export const PROVIDER_CURRENCY_CONFIG = {
  [CURRENCIES.USD.code]: [
    { provider: 'paypal', enabled: true }
  ],
  [CURRENCIES.ARS.code]: [
    { provider: 'mercadopago', enabled: true }
  ]
};

export default {
  ERROR_MESSAGES,
  LOADING_TEXTS,
  API_ENDPOINTS,
  PAYMENT_DESCRIPTIONS,
  CURRENCIES,
  PROVIDER_CURRENCY_CONFIG
}; 