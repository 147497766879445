/**
 * Sistema Financiero de Illustra
 * Este archivo centraliza la exportación de todas las funciones, constantes y servicios 
 * relacionados con el manejo de precios, monedas y pagos.
 */

// Importar internamente
import * as formatters from './formatters';
import * as constants from './constants';

// Exportar todas las constantes
export * from './constants';

// Exportar todas las funciones de formateo
export * from './formatters';

/**
 * API principal del sistema financiero
 */
const FinancialService = {
  // Funciones básicas de formateo
  formatPrice: formatters.formatPrice,
  formatNumber: formatters.formatNumber,
  formatPriceRange: formatters.formatPriceRange,
  
  // Aliases de formato para monedas específicas
  formatARS: formatters.formatARS,
  formatUSD: formatters.formatUSD,
  
  // Conversión de monedas
  convertCurrency: formatters.convertCurrency,
  extractNumericValue: formatters.extractNumericValue,
  
  // Constantes exportadas
  currencies: constants.CURRENCIES,
  exchangeRates: constants.EXCHANGE_RATES,
  paymentProviders: constants.PAYMENT_PROVIDERS,
  
  // Función para obtener la moneda predeterminada
  getDefaultCurrency: () => constants.CURRENCIES.ARS,
  
  // Utilidad para verificar si un valor numérico es válido
  isValidAmount: (amount) => {
    const numericValue = formatters.extractNumericValue(amount);
    return !isNaN(numericValue) && numericValue > 0;
  }
};

export default FinancialService; 