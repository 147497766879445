import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css'; // Asegúrate de que esta línea esté presente
import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container);

// Crear un div para modales a nivel global
const modalRoot = document.createElement('div');
modalRoot.id = 'modal-root';
modalRoot.style.position = 'fixed';
modalRoot.style.top = '0';
modalRoot.style.left = '0';
modalRoot.style.width = '100%';
modalRoot.style.height = '100%';
modalRoot.style.zIndex = '999999';
modalRoot.style.pointerEvents = 'none';
document.body.appendChild(modalRoot);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
