import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { FaBars, FaTimes, FaBell, FaUser, FaCog, FaPalette, FaShieldAlt, FaWallet, FaPlus, FaHeart, FaComment, FaEnvelope, FaCheckCircle, FaTimesCircle, FaTrash, FaEllipsisV, FaSignOutAlt, FaCompass, FaUserFriends, FaSearch } from 'react-icons/fa';
import styles from './Header.module.css';
import AddBalance from './addBalance';
import { collection, getDocs, query, where, orderBy, limit, doc, updateDoc, deleteDoc, writeBatch } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

const Header = () => {
  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showAddBalance, setShowAddBalance] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [loadingNotificationId, setLoadingNotificationId] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const headerRef = useRef(null);

  // Lista de tipos de notificaciones que queremos mostrar en el header
  const SUPPORTED_NOTIFICATION_TYPES = [
    'like',
    'comment',
    'follow',
    'service_request',
    'service_accepted',
    'service_rejected',
    'like_comment'
  ];

  // Detectar cambios de tamaño de pantalla
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      
      // Cerrar menú móvil si la pantalla se hace grande
      if (window.innerWidth > 768) {
        setShowMobileMenu(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Función para manejar el clic en una notificación
  const handleNotificationClick = async (notification) => {
    try {
      // Log para diagnóstico
      console.log('Notificación clickeada:', notification);
      
      // Mostrar indicador de carga
      setLoadingNotificationId(notification.id);
      
      // Marcar la notificación como leída
      const notificationRef = doc(db, 'users', user.uid, 'Notifications', notification.id);
      await updateDoc(notificationRef, {
        read: true
      });

      // Actualizar el estado local
      setNotifications(prev => prev.map(n => 
        n.id === notification.id ? {...n, read: true} : n
      ));
      
      if (!notification.read) {
        setNotificationCount(prev => Math.max(0, prev - 1));
      }

      // Pequeña pausa para mostrar el efecto visual
      await new Promise(resolve => setTimeout(resolve, 300));

      // Cerrar el dropdown de notificaciones
      setShowNotifications(false);
      
      // Extraer IDs necesarios (pueden estar en diferentes ubicaciones)
      const postId = notification.postId || (notification.data && notification.data.postId);
      const fromUserId = notification.fromUserId || (notification.data && notification.data.fromUserId) || notification.fromUser;
      const serviceId = notification.serviceId || (notification.data && notification.data.serviceId);
      
      // Navegar según el tipo de notificación
      console.log('Intentando navegar con tipo:', notification.type);
      console.log('IDs disponibles:', { postId, fromUserId, serviceId });
      
      let navigated = false;
      
      switch (notification.type) {
        case 'like':
        case 'comment':
        case 'like_comment':
          if (postId) {
            console.log('Navegando a post:', postId);
            navigate(`/post/${postId}`);
            navigated = true;
          } else if (fromUserId) {
            // Alternativa: ir al perfil del usuario que realizó la acción
            console.log('Alternativa: navegando al perfil del usuario:', fromUserId);
            navigate(`/profile/${fromUserId}`);
            navigated = true;
          }
          break;
        case 'follow':
          if (fromUserId) {
            console.log('Navegando a perfil:', fromUserId);
            navigate(`/profile/${fromUserId}`);
            navigated = true;
          }
          break;
        case 'service_request':
        case 'service_accepted':
        case 'service_rejected':
          if (serviceId) {
            console.log('Navegando a servicio:', serviceId);
            navigate(`/service/${serviceId}`);
            navigated = true;
          } else if (fromUserId) {
            // Alternativa: ir al perfil del usuario relacionado con el servicio
            console.log('Alternativa: navegando al perfil del usuario:', fromUserId);
            navigate(`/profile/${fromUserId}`);
            navigated = true;
          }
          break;
        default:
          console.log('Tipo de notificación no reconocido:', notification.type);
          break;
      }
      
      // Si no se pudo navegar con ninguna de las opciones específicas,
      // intentar navegar a una página general
      if (!navigated) {
        console.log('No se pudo navegar específicamente, intentando alternativa general');
        
        if (notification.type.includes('service')) {
          // Ir a la lista de servicios
          navigate('/explore-services');
        } else if (notification.type === 'follow') {
          // Ir a mi perfil
          navigate(`/profile/${user.uid}`);
        } else {
          // Ir a la página principal
          navigate('/explore');
        }
      }
      
      // Quitar indicador de carga
      setLoadingNotificationId(null);
    } catch (error) {
      console.error('Error al manejar la notificación:', error);
      setLoadingNotificationId(null);
    }
  };

  // Función para cargar notificaciones
  const loadNotifications = async () => {
    if (!user) return;
    
    try {
      const notificationsRef = collection(db, 'users', user.uid, 'Notifications');
      
      // Consulta para notificaciones no leídas de tipos soportados
      const q = query(
        notificationsRef,
        where('read', '==', false),
        where('type', 'in', SUPPORTED_NOTIFICATION_TYPES),
        orderBy('timestamp', 'desc'),
        limit(10)
      );
      
      const snapshot = await getDocs(q);
      
      const notificationData = snapshot.docs.map(doc => {
        // Obtener datos básicos
        const rawData = doc.data();
        console.log('Datos brutos de notificación:', rawData);
        
        // Procesar los datos para asegurar que los campos de referencia estén accesibles
        const processedData = {
          id: doc.id,
          ...rawData,
          // Extraer IDs de lugares específicos si existen
          postId: rawData.postId || (rawData.data && rawData.data.postId),
          fromUserId: rawData.fromUserId || (rawData.data && rawData.data.fromUserId),
          serviceId: rawData.serviceId || (rawData.data && rawData.data.serviceId),
        };
        
        return processedData;
      });
      
      // Verificar si las notificaciones tienen los campos necesarios
      notificationData.forEach(notification => {
        if (notification.type === 'like' || notification.type === 'comment' || notification.type === 'like_comment') {
          if (!notification.postId) {
            console.warn(`Notificación de tipo ${notification.type} sin postId:`, notification);
          }
        } else if (notification.type === 'follow') {
          if (!notification.fromUserId) {
            console.warn(`Notificación de follow sin fromUserId:`, notification);
          }
        } else if (notification.type.includes('service')) {
          if (!notification.serviceId) {
            console.warn(`Notificación de servicio sin serviceId:`, notification);
          }
        }
      });
      
      setNotifications(notificationData);
      setNotificationCount(notificationData.length);
    } catch (error) {
      console.error('Error al cargar notificaciones:', error);
    }
  };

  // Cerrar dropdowns cuando se hace click fuera
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        setShowMobileMenu(false);
      }
      
      if (!event.target.closest(`.${styles.notificationsContainer}`)) {
        setShowNotifications(false);
      }
      if (!event.target.closest(`.${styles.userMenu}`)) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);
  
  // Cargar notificaciones al inicio y periódicamente
  useEffect(() => {
    loadNotifications();
    
    // Verificar periódicamente cada 60 segundos
    const checkInterval = setInterval(loadNotifications, 60 * 1000);
    
    return () => clearInterval(checkInterval);
  }, [user]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };
  
  // Renderiza el icono según el tipo de notificación
  const getNotificationIcon = (type) => {
    switch (type) {
      case 'like':
        return <FaHeart />;
      case 'comment':
        return <FaComment />;
      case 'follow':
        return <FaUser />;
      case 'service_request':
        return <FaEnvelope />;
      case 'service_accepted':
        return <FaCheckCircle />;
      case 'service_rejected':
        return <FaTimesCircle />;
      case 'like_comment':
        return <FaHeart />;
      default:
        return <FaBell />;
    }
  };

  // Función para formatear la fecha
  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    
    const date = timestamp.toDate();
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays === 0) {
      // Si es hoy, mostrar la hora
      return date.toLocaleTimeString('es-AR', { 
        hour: '2-digit', 
        minute: '2-digit' 
      });
    } else if (diffDays === 1) {
      return 'Ayer';
    } else if (diffDays < 7) {
      return `Hace ${diffDays} días`;
    } else {
      return date.toLocaleDateString('es-AR', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
      });
    }
  };

  // Función para eliminar una notificación
  const handleDeleteNotification = async (event, notification) => {
    event.stopPropagation(); // Prevenir que se active el click de la notificación
    
    try {
      // Eliminar la notificación de Firestore
      const notificationRef = doc(db, 'users', user.uid, 'Notifications', notification.id);
      await deleteDoc(notificationRef);
      
      // Actualizar el estado local
      setNotifications(prev => prev.filter(n => n.id !== notification.id));
      if (!notification.read) {
        setNotificationCount(prev => Math.max(0, prev - 1));
      }
    } catch (error) {
      console.error('Error al eliminar la notificación:', error);
    }
  };

  // Función para eliminar todas las notificaciones
  const handleDeleteAllNotifications = async () => {
    if (!user || notifications.length === 0) return;
    
    try {
      // Eliminar cada notificación en Firestore
      const batch = writeBatch(db);
      notifications.forEach(notification => {
        const notificationRef = doc(db, 'users', user.uid, 'Notifications', notification.id);
        batch.delete(notificationRef);
      });
      await batch.commit();
      
      // Actualizar el estado local
      setNotifications([]);
      setNotificationCount(0);
    } catch (error) {
      console.error('Error al eliminar todas las notificaciones:', error);
    }
  };

  // Alternar menú móvil
  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    setShowNotifications(false);
    setShowUserMenu(false);
  };

  // Manejar clic en membresía
  const handleMembershipClick = () => {
    navigate('/membership');
    setShowMobileMenu(false);
  };

  return (
    <header className={styles.header} ref={headerRef}>
      <div className={styles.headerContainer}>
        {/* Logo */}
        <Link to="/" className={styles.logo}>
          <img src="/logo192.png" alt="Illustra" />
          <span>illustra</span>
        </Link>

        {/* Navegación Desktop (solo se muestra en pantallas grandes) */}
        {!isMobile && (
          <nav className={`${styles.nav} ${isMenuOpen ? styles.navActive : ''}`}>
            <Link to="/explore-services">Servicios</Link>
            <Link to="/explore">Explorar</Link>
            <Link to="/explore-users">Usuarios</Link>
          </nav>
        )}

        {/* Acciones de Usuario */}
        <div className={styles.userActions}>
          {user ? (
            <>
              {/* Balance */}
              <button 
                className={styles.balance} 
                onClick={() => setShowAddBalance(true)}
                title="Añadir balance"
                aria-label="Ver o añadir balance"
              >
                <FaWallet />
                <span>ARS ${(user.balance || 0).toLocaleString('es-AR')}</span>
                <FaPlus className={styles.plusIcon} />
              </button>

              {/* Notificaciones */}
              <div className={styles.notificationsContainer}>
                <button 
                  className={styles.iconButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowNotifications(!showNotifications);
                    setShowUserMenu(false);
                    if (!showNotifications) {
                      loadNotifications();
                    }
                  }}
                  aria-label={`Notificaciones${notificationCount > 0 ? ` (${notificationCount} sin leer)` : ''}`}
                >
                  <FaBell />
                  {notificationCount > 0 && (
                    <span className={styles.notificationBadge}>
                      {notificationCount}
                    </span>
                  )}
                </button>

                {showNotifications && (
                  <div className={styles.notificationsDropdown}>
                    <div className={styles.notificationsHeader}>
                      <h3>Notificaciones</h3>
                      {notifications.length > 0 && (
                        <button 
                          className={styles.clearAllButton}
                          onClick={handleDeleteAllNotifications}
                          title="Eliminar todas"
                        >
                          <FaTrash />
                        </button>
                      )}
                    </div>
                    <div className={styles.notificationsList}>
                      {notifications.length > 0 ? (
                        notifications.map((notification) => (
                          <div 
                            key={notification.id} 
                            className={`${styles.notificationItem} ${loadingNotificationId === notification.id ? styles.loading : ''} ${notification.read ? styles.read : ''}`}
                            onClick={() => handleNotificationClick(notification)}
                          >
                            <div className={`${styles.notificationIcon} ${
                              notification.type === 'like' || notification.type === 'like_comment' ? styles.likeIcon : 
                              notification.type === 'comment' ? styles.commentIcon : 
                              notification.type === 'follow' ? styles.followIcon : 
                              notification.type === 'service_request' ? styles.serviceIcon : 
                              notification.type === 'service_accepted' ? styles.acceptedIcon : 
                              notification.type === 'service_rejected' ? styles.rejectedIcon : 
                              styles.defaultIcon
                            }`}>
                              {getNotificationIcon(notification.type)}
                            </div>
                            <div className={styles.notificationContent}>
                              <p className={styles.notificationMessage}>
                                {notification.message || notification.title}
                              </p>
                              <span className={styles.notificationTime}>
                                {formatDate(notification.timestamp)}
                              </span>
                            </div>
                            {loadingNotificationId === notification.id ? (
                              <div className={styles.loadingIndicator}></div>
                            ) : (
                              <button 
                                className={styles.deleteButton}
                                onClick={(e) => handleDeleteNotification(e, notification)}
                                title="Eliminar notificación"
                              >
                                <FaTimes />
                              </button>
                            )}
                          </div>
                        ))
                      ) : (
                        <div className={styles.noNotifications}>
                          <FaBell className={styles.emptyIcon} />
                          <p>No hay notificaciones nuevas</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* Menú de Usuario */}
              <div className={styles.userMenu}>
                <button 
                  className={styles.userButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowUserMenu(!showUserMenu);
                    setShowNotifications(false);
                  }}
                  aria-label="Menú de usuario"
                >
                  <img 
                    src={user.photoURL || '/default-avatar.png'} 
                    alt="Perfil"
                    className={styles.userAvatar}
                  />
                  <span className={styles.userButtonName}>
                    {user.username || user.displayName || user.email.split('@')[0]}
                  </span>
                </button>

                {showUserMenu && (
                  <div className={styles.userDropdown}>
                    <div className={styles.userInfo}>
                      <img 
                        src={user.photoURL || '/default-avatar.png'} 
                        alt="Perfil"
                        className={styles.userAvatarLarge}
                      />
                      <div>
                        <p className={styles.userName}>
                          {user.username || user.displayName || user.email.split('@')[0]}
                        </p>
                        <small className={styles.userEmail}>{user.email}</small>
                      </div>
                    </div>

                    <div className={styles.dropdownLinks}>
                      {/* Enlaces de navegación principales - solo visibles en móvil */}
                      {isMobile && (
                        <>
                          <Link to="/explore" className={styles.mobileNavLink}>
                            <FaCompass /> Explorar
                          </Link>
                          <Link to="/explore-services" className={styles.mobileNavLink}>
                            <FaPalette /> Servicios
                          </Link>
                          <Link to="/explore-users" className={styles.mobileNavLink}>
                            <FaUserFriends /> Usuarios
                          </Link>
                          <div className={styles.dropdownDivider}></div>
                        </>
                      )}
                      
                      {/* Enlaces de usuario existentes */}
                      <Link to={`/profile/${user.uid}`}>
                        <FaUser /> Mi Perfil
                      </Link>
                      <Link to="/workbench">
                        <FaPalette /> Órdenes/Pedidos
                      </Link>
                      <Link to="/dashboard">
                        <FaCompass /> Dashboard
                      </Link>
                      {user.role === 'admin' && (
                        <Link to="/admin-dashboard">
                          <FaShieldAlt /> Panel Admin
                        </Link>
                      )}
                      <Link to="/configuration">
                        <FaCog /> Configuración
                      </Link>
                      <Link to="/membership" className={styles.membershipLink}>
                        <FaPlus /> Membresía
                      </Link>
                      <button onClick={handleLogout}>
                        <FaSignOutAlt /> Cerrar Sesión
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <Link to="/login" className={styles.loginButton}>
              Iniciar Sesión
            </Link>
          )}
        </div>
      </div>

      {/* Modal de AddBalance */}
      {showAddBalance && (
        <AddBalance onClose={() => setShowAddBalance(false)} />
      )}
    </header>
  );
};

export default Header; 