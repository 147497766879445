/**
 * Funciones de formateo para el sistema financiero de Illustra
 * Centraliza todas las funciones relacionadas con formateo de precios y conversiones
 */

import { CURRENCIES, PRICE_FORMATS, EXCHANGE_RATES } from './constants';

/**
 * Formatea un precio según la moneda especificada
 * @param {number|string} amount - Cantidad a formatear
 * @param {string} currency - Código de moneda (ARS por defecto)
 * @param {object} options - Opciones adicionales de formateo
 * @returns {string} - Precio formateado
 */
export const formatPrice = (amount, currency = 'ARS', options = {}) => {
  try {
    // Validación y conversión de entrada
    if (amount === null || amount === undefined || amount === '') {
      return formatZeroAmount(currency);
    }

    let numericAmount;
    if (typeof amount === 'string') {
      // Limpiar la cadena de caracteres no numéricos
      const cleanedAmount = amount.replace(/[^\d.-]/g, '');
      numericAmount = parseFloat(cleanedAmount);
    } else {
      numericAmount = parseFloat(amount);
    }

    if (isNaN(numericAmount) || !isFinite(numericAmount)) {
      return formatZeroAmount(currency);
    }

    // Obtener formato según moneda
    const format = PRICE_FORMATS[currency] || PRICE_FORMATS.ARS;
    
    // Unificar opciones de formateo
    const formatOptions = {
      ...format.options,
      ...options
    };

    return new Intl.NumberFormat(format.locale, formatOptions).format(numericAmount);
  } catch (error) {
    console.error('Error al formatear precio:', error);
    return formatZeroAmount(currency);
  }
};

/**
 * Formatea un valor cero según la moneda
 * @param {string} currency - Código de moneda
 * @returns {string} - Representación del valor cero formateado
 */
const formatZeroAmount = (currency = 'ARS') => {
  const format = PRICE_FORMATS[currency] || PRICE_FORMATS.ARS;
  return new Intl.NumberFormat(format.locale, format.options).format(0);
};

/**
 * Formatea un rango de precios
 * @param {number} minPrice - Precio mínimo
 * @param {number} maxPrice - Precio máximo
 * @param {string} currency - Código de moneda
 * @returns {string} - Rango de precios formateado
 */
export const formatPriceRange = (minPrice, maxPrice, currency = 'ARS') => {
  if (minPrice === maxPrice) {
    return formatPrice(minPrice, currency);
  }
  
  if (minPrice === null || minPrice === undefined || isNaN(minPrice)) {
    minPrice = 0;
  }
  
  if (maxPrice === null || maxPrice === undefined || isNaN(maxPrice)) {
    return `${formatPrice(minPrice, currency)}+`;
  }
  
  return `${formatPrice(minPrice, currency)} - ${formatPrice(maxPrice, currency)}`;
};

/**
 * Convierte un precio entre monedas
 * @param {number} amount - Cantidad a convertir
 * @param {string} fromCurrency - Moneda origen
 * @param {string} toCurrency - Moneda destino
 * @returns {number} - Cantidad convertida
 */
export const convertCurrency = (amount, fromCurrency, toCurrency) => {
  // Si las monedas son iguales, devolver el mismo monto
  if (fromCurrency === toCurrency) {
    return amount;
  }
  
  const numericAmount = parseFloat(amount);
  if (isNaN(numericAmount)) {
    return 0;
  }
  
  // Conversión ARS <-> USD
  if (fromCurrency === 'ARS' && toCurrency === 'USD') {
    return numericAmount * EXCHANGE_RATES.ARS_TO_USD;
  }
  
  if (fromCurrency === 'USD' && toCurrency === 'ARS') {
    return numericAmount * EXCHANGE_RATES.USD_TO_ARS;
  }
  
  // Devolver el monto original si no hay conversión definida
  return numericAmount;
};

/**
 * Extrae y normaliza un valor numérico de un precio formateado
 * @param {string|number} formattedPrice - Precio formateado o número
 * @returns {number} - Valor numérico
 */
export const extractNumericValue = (formattedPrice) => {
  if (typeof formattedPrice === 'number') {
    return formattedPrice;
  }
  
  if (!formattedPrice) {
    return 0;
  }
  
  try {
    // Remover todos los caracteres no numéricos excepto el punto decimal
    const numericString = formattedPrice.toString().replace(/[^\d.-]/g, '');
    const value = parseFloat(numericString);
    return isNaN(value) ? 0 : value;
  } catch (error) {
    console.error('Error al extraer valor numérico:', error);
    return 0;
  }
};

/**
 * Formatea un número (no monetario)
 * @param {number} number - Número a formatear
 * @param {number} decimals - Cantidad de decimales
 * @param {string} locale - Configuración regional (es-AR por defecto)
 * @returns {string} - Número formateado
 */
export const formatNumber = (number, decimals = 0, locale = 'es-AR') => {
  try {
    if (number === null || number === undefined || isNaN(number)) {
      return '0';
    }
    
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    }).format(number);
  } catch (error) {
    console.error('Error al formatear número:', error);
    return '0';
  }
};

// Exportamos aliases para mantener compatibilidad con código existente
export const formatCurrency = formatPrice;
export const formatARS = (amount) => formatPrice(amount, 'ARS');
export const formatUSD = (amount) => formatPrice(amount, 'USD'); 