import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { IoClose, IoNotifications } from 'react-icons/io5';
import { collection, query, where, orderBy, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../../firebaseConfig';
import { handleNotificationNavigation } from './NotificationNavigation';
import styles from './Notifications.module.css';

const Notifications = ({ onClose }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadNotifications();
  }, []);

  const loadNotifications = async () => {
    try {
      setLoading(true);
      // Obtener el ID del usuario actual (asumiendo que tienes una forma de obtenerlo)
      const userId = auth.currentUser?.uid;
      if (!userId) throw new Error('Usuario no autenticado');

      const notificationsRef = collection(db, 'users', userId, 'Notifications');
      const q = query(
        notificationsRef,
        orderBy('timestamp', 'desc')
      );

      const querySnapshot = await getDocs(q);
      const notificationsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().timestamp?.toDate() || new Date()
      }));

      setNotifications(notificationsData);
      setError(null);
    } catch (err) {
      setError('Error al cargar las notificaciones');
      console.error('Error cargando notificaciones:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleNotificationClick = async (notification) => {
    try {
      if (!notification.read) {
        const userId = auth.currentUser?.uid;
        if (!userId) throw new Error('Usuario no autenticado');

        const notificationRef = doc(db, 'users', userId, 'Notifications', notification.id);
        await updateDoc(notificationRef, { read: true });

        setNotifications(prevNotifications =>
          prevNotifications.map(n =>
            n.id === notification.id ? { ...n, read: true } : n
          )
        );
      }
      handleNotificationNavigation(notification, onClose);
    } catch (error) {
      console.error('Error al marcar la notificación como leída:', error);
    }
  };

  return (
    <motion.div
      className={styles.overlay}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <motion.div
        className={styles.container}
        initial={{ x: '100%', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: '100%', opacity: 0 }}
        transition={{ type: 'spring', damping: 25, stiffness: 500 }}
        onClick={e => e.stopPropagation()}
      >
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <IoNotifications className={styles.headerIcon} />
            <h2>Notificaciones</h2>
          </div>
          <button
            className={styles.closeButton}
            onClick={onClose}
            aria-label="Cerrar notificaciones"
          >
            <IoClose />
          </button>
        </div>

        <div className={styles.content}>
          {loading ? (
            <div className={styles.loadingState}>
              <div className={styles.spinner} />
              <p>Cargando notificaciones...</p>
            </div>
          ) : error ? (
            <div className={styles.errorState}>
              <p>{error}</p>
              <button onClick={loadNotifications}>Reintentar</button>
            </div>
          ) : notifications.length === 0 ? (
            <div className={styles.emptyState}>
              <IoNotifications className={styles.emptyIcon} />
              <p>No tienes notificaciones</p>
            </div>
          ) : (
            <div className={styles.notificationsList}>
              {notifications.map((notification) => (
                <motion.div
                  key={notification.id}
                  className={`${styles.notificationItem} ${
                    notification.read ? styles.read : ''
                  }`}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  whileHover={{ scale: 1.02 }}
                  onClick={() => handleNotificationClick(notification)}
                >
                  <div className={styles.notificationContent}>
                    <h3>{notification.title}</h3>
                    <p>{notification.message}</p>
                    <span className={styles.timestamp}>
                      {new Date(notification.createdAt).toLocaleString()}
                    </span>
                  </div>
                  {!notification.read && (
                    <div className={styles.unreadIndicator} />
                  )}
                </motion.div>
              ))}
            </div>
          )}
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Notifications; 