import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { FaTimes, FaInfoCircle, FaSpinner } from 'react-icons/fa';
import { UserContext } from '../../context/UserContext';
import './addBalance.css';
import { useTheme } from '../darkMode/ThemeProvider';
import { toast } from 'react-toastify';
import paymentService, { PAYMENT_PROVIDERS, calculateFees, formatCurrency } from '../../services/payments/paymentService';
import { ERROR_MESSAGES, LOADING_TEXTS, WALLET_TYPES } from '../utils/constants';
import { getWallets, updateWalletBalance } from '../../services/payments/walletService';

// Definir localmente las constantes que faltan
const CURRENCIES = {
  ARS: {
    code: 'ARS',
    name: 'Pesos Argentinos',
    symbol: '$',
    locale: 'es-AR'
  },
  USD: {
    code: 'USD',
    name: 'Dólares',
    symbol: '$',
    locale: 'en-US'
  }
};

const PROVIDER_CURRENCY_CONFIG = {
  'ARS': [
    { 
      provider: PAYMENT_PROVIDERS.MERCADOPAGO,
      label: 'MercadoPago'
    }
  ],
  'USD': [
    {
      provider: PAYMENT_PROVIDERS.PAYPAL,
      label: 'PayPal'
    }
  ]
};

// Renderizar en un portal para evitar problemas de posicionamiento
const AddBalancePortal = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.body
  );
};

const AddBalance = ({ onClose, initialWalletType }) => {
  const { user, authToken } = useContext(UserContext);
  const { theme } = useTheme();
  const [baseAmount, setBaseAmount] = useState('');
  const [error, setError] = useState('');
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(PAYMENT_PROVIDERS.MERCADOPAGO);
  const [selectedCurrency, setSelectedCurrency] = useState(CURRENCIES.ARS.code);
  const modalRef = useRef(null);
  const currentUser = user; // Usar user del UserContext como currentUser
  const [wallets, setWallets] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState(initialWalletType || WALLET_TYPES.ARS);
  const [loadingWallets, setLoadingWallets] = useState(true);
  
  // Añadir los estados que faltan
  const [numericBaseAmount, setNumericBaseAmount] = useState(0);
  const [platformFee, setPlatformFee] = useState(0);
  const [providerFee, setProviderFee] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  // const numericBaseAmount = parseInt(baseAmount, 10) || 0;
  const fees = calculateFees(numericBaseAmount, selectedProvider);

  // Definir handleClose al principio, antes de cualquier efecto
  const handleClose = useCallback(() => {
    // Limpiar el estado antes de cerrar
    setBaseAmount('');
    setAcceptedTerms(false);
    setError('');
    
    // Llamar al callback de cierre
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  // Cerrar cuando se presiona Escape
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleClose]);

  // Cerrar cuando se hace clic fuera
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        if (event.target.classList.contains('modal-addBalance-overlay')) {
          handleClose();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClose]);

  // Efecto para recalcular el total
  useEffect(() => {
    const amount = parseFloat(baseAmount);
    if (!isNaN(amount) && amount > 0) {
      setNumericBaseAmount(amount);
      
      // No aplicamos comisiones para recargas
      setPlatformFee(0);
      setProviderFee(0);
      setTotalAmount(amount); // El total es el mismo que el monto base, sin comisiones
    } else {
      setNumericBaseAmount(0);
      setPlatformFee(0);
      setProviderFee(0);
      setTotalAmount(0);
    }
  }, [baseAmount, selectedProvider]);

  // Cargar billeteras al montar el componente
  useEffect(() => {
    if (currentUser?.uid) {
      loadUserWallets();
    }
  }, [currentUser?.uid]);

  // Actualizar billetera seleccionada al cambiar el proveedor
  useEffect(() => {
    // Si el proveedor es Mercado Pago, seleccionar la billetera ARS
    // Si el proveedor es PayPal, seleccionar la billetera USD
    if (selectedProvider === PAYMENT_PROVIDERS.MERCADOPAGO) {
      setSelectedWallet(WALLET_TYPES.ARS);
    } else {
      setSelectedWallet(WALLET_TYPES.USD);
    }
  }, [selectedProvider]);

  // Usar initialWalletType si está disponible
  useEffect(() => {
    if (initialWalletType) {
      setSelectedWallet(initialWalletType);
      // Actualizar también la moneda seleccionada basada en el tipo de billetera
      setSelectedCurrency(initialWalletType === WALLET_TYPES.USD ? CURRENCIES.USD.code : CURRENCIES.ARS.code);
      // Actualizar el proveedor basado en la moneda
      setSelectedProvider(initialWalletType === WALLET_TYPES.USD ? PAYMENT_PROVIDERS.PAYPAL : PAYMENT_PROVIDERS.MERCADOPAGO);
    }
  }, [initialWalletType]);

  const loadUserWallets = async () => {
    try {
      setLoadingWallets(true);
      const userWallets = await getWallets(currentUser.uid);
      setWallets(userWallets);
    } catch (error) {
      console.error('Error al cargar billeteras:', error);
      toast.error('No se pudieron cargar las billeteras');
    } finally {
      setLoadingWallets(false);
    }
  };

  const handleCurrencyChange = (currency) => {
    // Primero cambiamos la moneda
    setSelectedCurrency(currency);
    
    // Luego seleccionamos automáticamente el proveedor correcto para esta moneda
    const availableProviders = PROVIDER_CURRENCY_CONFIG[currency] || [];
    const defaultProvider = availableProviders.length > 0 ? availableProviders[0].provider : null;
    
    if (defaultProvider) {
      setSelectedProvider(defaultProvider);
    }
  };

  const handleProviderChange = (provider) => {
    // Determinar qué moneda es compatible con este proveedor
    let compatibleCurrency = null;
    
    // Buscar en todas las monedas configuradas
    Object.entries(PROVIDER_CURRENCY_CONFIG).forEach(([currency, providers]) => {
      if (providers.some(p => p.provider === provider)) {
        compatibleCurrency = currency;
      }
    });
    
    if (compatibleCurrency) {
      // Si encontramos una moneda compatible, la seleccionamos
      setSelectedCurrency(compatibleCurrency);
      // Y luego seleccionamos el proveedor
      setSelectedProvider(provider);
    } else {
      // Si no hay moneda compatible, mostramos un error
      toast.error(ERROR_MESSAGES.CURRENCY_MISMATCH);
    }
  };

  // Formatear el monto según la moneda seleccionada
  const formatAmount = useCallback((amount) => {
    const currencyInfo = Object.values(CURRENCIES).find(c => c.code === selectedCurrency);
    return formatCurrency(amount, currencyInfo?.code || 'ARS');
  }, [selectedCurrency]);

  const handleSubmit = useCallback(async () => {
    setError('');
    setLoadingRequest(true);

    try {
      if (!numericBaseAmount || numericBaseAmount <= 0) {
        throw new Error(ERROR_MESSAGES.VALIDATION_ERROR);
      }
      
      if (!acceptedTerms) {
        throw new Error(ERROR_MESSAGES.TERMS_ERROR);
      }

      if (!authToken) {
        throw new Error(ERROR_MESSAGES.UNAUTHORIZED);
      }

      // Crear el pago utilizando el servicio centralizado
      const paymentData = {
        amount: numericBaseAmount,
        provider: selectedProvider,
        walletType: selectedWallet,
        currency: selectedCurrency,
        userId: user.uid,
        description: `Carga de saldo - ${user.username || user.email}`
      };

      console.log('Creando pago con los siguientes datos:', paymentData);
      
      // Mostrar mensaje de procesamiento
      toast.info(`Procesando pago con ${selectedProvider === PAYMENT_PROVIDERS.MERCADOPAGO ? 'MercadoPago' : 'PayPal'}...`);
      
      try {
        // Hacer la llamada real a la API
        const response = await paymentService.createPayment(paymentData, authToken);
        
        console.log('Respuesta del servicio de pagos:', response);

        if (!response || !response.paymentUrl) {
          throw new Error('La respuesta del servidor no contiene una URL de pago válida');
        }

        // Redirigir a la URL de pago
        toast.success('Redirigiendo a la página de pago...');
        
        // Pequeña pausa para que el usuario vea el mensaje
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Redirigir al usuario
        window.location.href = response.paymentUrl;
      } catch (apiError) {
        console.error('Error en la API de pagos:', apiError);
        
        // Mensajes de error específicos según el tipo de error
        if (apiError.message.includes('No autorizado') || apiError.message.includes('Token inválido')) {
          toast.error('Tu sesión ha expirado. Por favor, inicia sesión nuevamente.');
          // Aquí podrías redirigir al usuario a la página de login
        } else if (apiError.message.includes('URL de pago válida')) {
          toast.error('No se pudo generar la URL de pago. Por favor, intenta con otro método de pago.');
        } else {
          toast.error(`Error al procesar el pago: ${apiError.message}`);
        }
        
        setError(apiError.message || ERROR_MESSAGES.GENERIC_ERROR);
        setLoadingRequest(false);
      }
    } catch (error) {
      console.error('Error creating payment:', error);
      
      // Mensaje de error más descriptivo
      let errorMessage = error.message || ERROR_MESSAGES.GENERIC_ERROR;
      
      setError(errorMessage);
      toast.error(errorMessage);
      setLoadingRequest(false);
    }
  }, [numericBaseAmount, authToken, acceptedTerms, selectedProvider, selectedCurrency, user, handleClose, selectedWallet]);

  // Determinar qué proveedores están disponibles para la moneda seleccionada
  const availableProviders = PROVIDER_CURRENCY_CONFIG[selectedCurrency] || [];

  return (
    <AddBalancePortal>
      <div className={`modal-addBalance-overlay ${theme === 'dark' ? 'dark' : ''}`}>
        <div className="modal-addBalance-content" ref={modalRef}>
          <div className="modal-addBalance-header">
            <h3>Cargar Saldo</h3>
            <button 
              className="modal-addBalance-close-button" 
              onClick={handleClose}
              aria-label="Cerrar"
            >
              <FaTimes />
            </button>
          </div>
          
          <div className="modal-addBalance-body">
            {error && (
              <div className="modal-addBalance-alert-danger">
                {error}
              </div>
            )}
            
            <div className="modal-addBalance-form-group">
              <label>Moneda y método de pago</label>
              <div className="modal-addBalance-payment-options">
                <button
                  type="button"
                  className={`modal-addBalance-payment-option ${selectedCurrency === CURRENCIES.ARS.code ? 'active' : ''}`}
                  onClick={() => handleCurrencyChange(CURRENCIES.ARS.code)}
                >
                  <div className="modal-addBalance-payment-option-content">
                    <div className="modal-addBalance-payment-option-currency">
                      <span>ARS</span>
                    </div>
                    <div className="modal-addBalance-payment-option-provider">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/mercadopago-logo.png`}
                        alt="MercadoPago"
                        className="modal-addBalance-payment-logo"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "https://www.mercadopago.com/org-img/MP3/API/logos/mp-logo.png";
                        }}
                      />
                      <span>MercadoPago</span>
                    </div>
                  </div>
                </button>

                <button
                  type="button"
                  className="modal-addBalance-payment-option disabled"
                  disabled
                  title="PayPal no está disponible en este momento"
                >
                  <div className="modal-addBalance-payment-option-content">
                    <div className="modal-addBalance-payment-option-currency">
                      <span>USD</span>
                    </div>
                    <div className="modal-addBalance-payment-option-provider">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/paypal-logo.png`}
                        alt="PayPal"
                        className="modal-addBalance-payment-logo"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg";
                        }}
                      />
                      <span>PayPal (Próximamente)</span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            
            <div className="modal-addBalance-form-group">
              <label htmlFor="amount">Monto a cargar ({selectedCurrency})</label>
              <input
                type="number"
                id="amount"
                className="modal-addBalance-input"
                value={baseAmount}
                onChange={(e) => setBaseAmount(e.target.value)}
                placeholder="Ingresa el monto"
                min="1"
                disabled={loadingRequest}
              />
            </div>
            
            {numericBaseAmount > 0 && (
              <div className="modal-addBalance-summary">
                <div className="modal-addBalance-summary-item">
                  <span>Monto base:</span>
                  <span>{formatAmount(numericBaseAmount)}</span>
                </div>
                <div className="modal-addBalance-summary-total">
                  <span>Total a pagar:</span>
                  <span>{formatAmount(totalAmount)}</span>
                </div>
              </div>
            )}
            
            {selectedProvider === PAYMENT_PROVIDERS.PAYPAL && (
              <div className="modal-addBalance-info-message">
                <p>
                  <strong>Nota:</strong> Al proceder, serás redirigido a PayPal para completar el pago en dólares (USD).
                  No se aplican comisiones para recargas con PayPal.
                </p>
              </div>
            )}
            
            {selectedProvider === PAYMENT_PROVIDERS.MERCADOPAGO && (
              <div className="modal-addBalance-info-message">
                <p>
                  <strong>Importante:</strong> Al proceder, serás redirigido a MercadoPago para completar el pago en pesos argentinos (ARS).
                  No se aplican comisiones para recargas con MercadoPago.
                </p>
              </div>
            )}
            
            <div className="modal-addBalance-form-group">
              <div className="modal-addBalance-checkbox-container">
                <input
                  type="checkbox"
                  id="terms"
                  checked={acceptedTerms}
                  onChange={() => setAcceptedTerms(!acceptedTerms)}
                  disabled={loadingRequest}
                />
                <label htmlFor="terms">
                  Acepto los <a href="/terms" target="_blank" rel="noopener noreferrer">términos y condiciones</a>
                </label>
              </div>
            </div>
          </div>
          
          <div className="modal-addBalance-footer">
            <button
              className="modal-addBalance-button-secondary"
              onClick={handleClose}
              disabled={loadingRequest}
            >
              Cancelar
            </button>
            <button
              className="modal-addBalance-button-primary"
              onClick={handleSubmit}
              disabled={!numericBaseAmount || numericBaseAmount <= 0 || !acceptedTerms || loadingRequest}
            >
              {loadingRequest ? LOADING_TEXTS.PROCESSING : 'Proceder al Pago'}
            </button>
          </div>
        </div>
      </div>
    </AddBalancePortal>
  );
};

export default AddBalance;