/**
 * Servicio centralizado para manejar operaciones de pago
 * Este servicio abstrae las operaciones de pago para diferentes proveedores
 */

import { db } from '../../firebaseConfig';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { CURRENCIES } from './constants';
import { 
  createTransaction, 
  TRANSACTION_TYPES, 
  TRANSACTION_STATUS,
  updateTransactionStatus 
} from './transactionService';

// Constantes
export const PAYMENT_PROVIDERS = {
  MERCADOPAGO: 'mercadopago',
  PAYPAL: 'paypal',
  STRIPE: 'stripe'
};

// Comisiones actualizadas
export const PAYMENT_FEES = {
  PLATFORM: 0.05, // 5% comisión de la plataforma
  MERCADOPAGO: 0.07, // 7% comisión de MercadoPago
  PAYPAL: 0.0349, // 3.49% comisión de PayPal
  STRIPE: 0.029 // 2.9% comisión de Stripe
};

export const PAYMENT_STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILURE: 'failure'
};

// Tasas de conversión (simuladas - en producción deberían obtenerse de una API)
export const EXCHANGE_RATES = {
  USD_TO_ARS: 900, // 1 USD = 900 ARS (ejemplo)
  ARS_TO_USD: 1/900 // 1 ARS = 0.00111 USD (ejemplo)
};

/**
 * Crea una solicitud de pago y registra la transacción
 * @param {Object} paymentData - Datos del pago
 * @param {string} authToken - Token de autenticación
 * @returns {Promise<Object>} - Respuesta del servidor
 */
export const createPayment = async (paymentData, authToken) => {
  try {
    // Validar datos mínimos
    if (!paymentData.amount || !paymentData.userId) {
      throw new Error('Datos de pago incompletos');
    }

    // Crear la transacción primero
    const transactionData = {
      userId: paymentData.userId,
      type: TRANSACTION_TYPES.DEPOSIT,
      amount: paymentData.amount,
      currency: 'ARS',
      provider: paymentData.provider || PAYMENT_PROVIDERS.MERCADOPAGO,
      description: paymentData.description || 'Depósito de fondos',
      status: TRANSACTION_STATUS.PENDING,
      metadata: paymentData.metadata || {}
    };

    const transaction = await createTransaction(transactionData);

    // Preparar datos para el proveedor de pago
    const providerData = {
      amount: paymentData.amount,
      transactionId: transaction.transactionId,
      provider: paymentData.provider || PAYMENT_PROVIDERS.MERCADOPAGO,
      currency: 'ARS',
      description: paymentData.description || 'Depósito de fondos',
      metadata: {
        userId: paymentData.userId,
        ...paymentData.metadata
      }
    };

    // Llamar a la API para crear el pago
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/payments/create-balance-payment`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(providerData)
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Error al crear el pago');
    }

    const responseData = await response.json();

    // Asegurarse de que tenemos una URL de pago
    const paymentUrl = responseData.init_point || 
                      responseData.sandbox_init_point || 
                      responseData.approvalUrl || 
                      responseData.paymentUrl;

    if (!paymentUrl) {
      throw new Error('No se pudo obtener la URL de pago');
    }

    return {
      ...responseData,
      paymentUrl,
      transactionId: transaction.transactionId
    };
  } catch (error) {
    console.error('Error al crear el pago:', error);
    throw error;
  }
};

/**
 * Verifica el estado de un pago y actualiza la transacción
 * @param {string} paymentId - ID del pago
 * @param {string} transactionId - ID de la transacción
 * @param {string} authToken - Token de autenticación
 * @returns {Promise<Object>} - Información del pago
 */
export const verifyPayment = async (paymentId, transactionId, authToken) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/payments/verify/${paymentId}`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`
        }
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Error al verificar el pago');
    }

    const paymentData = await response.json();

    // Actualizar estado de la transacción
    if (paymentData.status === 'approved') {
      await updateTransactionStatus(transactionId, TRANSACTION_STATUS.COMPLETED, {
        paymentId,
        paymentStatus: paymentData.status,
        paymentDetails: paymentData
      });
    } else if (paymentData.status === 'rejected' || paymentData.status === 'cancelled') {
      await updateTransactionStatus(transactionId, TRANSACTION_STATUS.FAILED, {
        paymentId,
        paymentStatus: paymentData.status,
        paymentDetails: paymentData
      });
    }

    return paymentData;
  } catch (error) {
    console.error('Error al verificar el pago:', error);
    throw error;
  }
};

/**
 * Actualiza el balance de un usuario
 * @param {string} userId - ID del usuario
 * @param {number} amount - Monto a añadir
 * @param {string} authToken - Token de autenticación
 * @param {string} currency - Moneda del monto (por defecto USD)
 * @returns {Promise<Object>} - Usuario actualizado
 */
export const updateUserBalance = async (userId, amount, authToken, currency = CURRENCIES.USD.code) => {
  try {
    // Primero obtenemos el usuario actual
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      throw new Error('Usuario no encontrado');
    }
    
    const userData = userDoc.data();
    const currentBalance = userData.balance || 0;
    
    // Convertir a USD si es necesario (el balance siempre se guarda en USD)
    let amountInUSD = amount;
    if (currency === CURRENCIES.ARS.code) {
      amountInUSD = amount * EXCHANGE_RATES.ARS_TO_USD;
    }
    
    const newBalance = currentBalance + amountInUSD;
    
    // Actualizamos el balance
    await updateDoc(userRef, {
      balance: newBalance,
      lastUpdated: new Date().toISOString()
    });
    
    // Obtenemos el usuario actualizado
    const updatedUserDoc = await getDoc(userRef);
    return updatedUserDoc.data();
  } catch (error) {
    console.error('Error al actualizar el balance:', error);
    throw error;
  }
};

/**
 * Calcula las comisiones para un pago
 * @param {number} baseAmount - Monto base
 * @param {string} provider - Proveedor de pago
 * @returns {Object} - Desglose de comisiones
 */
export const calculateFees = (baseAmount, provider = PAYMENT_PROVIDERS.MERCADOPAGO) => {
  const platformFee = baseAmount * PAYMENT_FEES.PLATFORM;
  const providerFee = baseAmount * PAYMENT_FEES[provider.toUpperCase()];
  const totalFees = platformFee + providerFee;
  const finalAmount = baseAmount - totalFees;

  return {
    baseAmount,
    platformFee,
    providerFee,
    totalFees,
    finalAmount
  };
};

/**
 * Formatea un monto en la moneda especificada
 * @param {number} amount - Monto a formatear
 * @param {string} currency - Código ISO de la moneda (por defecto 'ARS')
 * @returns {string} - Monto formateado
 */
export const formatCurrency = (amount, currency = 'ARS') => {
  // Determinar la configuración regional según la moneda
  let locale = 'es-AR'; // Por defecto para pesos argentinos
  
  // Ajustar locale según moneda
  if (currency === 'USD') {
    locale = 'en-US';
  } else if (currency === 'EUR') {
    locale = 'es-ES';
  }
  
  // Asegurarse que currency sea un código ISO válido (ARS, USD, EUR)
  // y no un locale como es-AR
  let currencyCode = currency;
  
  const options = {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  try {
    return new Intl.NumberFormat(locale, options).format(amount);
  } catch (error) {
    console.error(`Error al formatear moneda ${currency}:`, error);
    // Fallback seguro en caso de error
    return `${currencyCode} ${amount.toFixed(2)}`;
  }
};

/**
 * Convierte un monto entre monedas
 * @param {number} amount - Monto a convertir
 * @param {string} fromCurrency - Moneda de origen
 * @param {string} toCurrency - Moneda de destino
 * @returns {number} - Monto convertido
 */
export const convertCurrency = (amount, fromCurrency, toCurrency) => {
  if (fromCurrency === toCurrency) {
    return amount;
  }
  
  if (fromCurrency === CURRENCIES.USD.code && toCurrency === CURRENCIES.ARS.code) {
    return amount * EXCHANGE_RATES.USD_TO_ARS;
  }
  
  if (fromCurrency === CURRENCIES.ARS.code && toCurrency === CURRENCIES.USD.code) {
    return amount * EXCHANGE_RATES.ARS_TO_USD;
  }
  
  return amount; // Si no hay conversión definida, devolver el monto original
};

/**
 * Sincroniza una transacción de retiro específica
 * @param {string} userId - ID del usuario
 * @param {string} withdrawalRequestId - ID de la solicitud de retiro
 * @param {string} authToken - Token de autenticación
 * @returns {Promise<Object>} - Resultado de la sincronización
 */
export const syncWithdrawalTransaction = async (userId, withdrawalRequestId, authToken) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/sync-withdrawal-transactions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
      body: JSON.stringify({
        userId,
        withdrawalRequestId
      })
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al sincronizar transacción');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error al sincronizar transacción:', error);
    throw error;
  }
};

/**
 * Sincroniza todas las transacciones de retiro de un usuario
 * @param {string} userId - ID del usuario
 * @param {string} authToken - Token de autenticación
 * @returns {Promise<Object>} - Resultado de la sincronización
 */
export const syncUserTransactions = async (userId, authToken) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/sync-user-transactions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
      body: JSON.stringify({
        userId
      })
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al sincronizar transacciones');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error al sincronizar transacciones del usuario:', error);
    throw error;
  }
};

export default {
  createPayment,
  verifyPayment,
  updateUserBalance,
  calculateFees,
  formatCurrency,
  convertCurrency,
  PAYMENT_PROVIDERS,
  PAYMENT_FEES,
  PAYMENT_STATUS,
  EXCHANGE_RATES
}; 