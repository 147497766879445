/**
 * Constantes para el sistema financiero de Illustra
 * Centraliza todas las constantes relacionadas con monedas, tasas de cambio y formato de precios
 */

// Monedas soportadas
export const CURRENCIES = {
  ARS: {
    code: 'ARS',
    symbol: '$',
    name: 'Peso argentino',
    locale: 'es-AR',
    isDefault: true
  },
  USD: {
    code: 'USD',
    symbol: 'US$',
    name: 'Dólar estadounidense',
    locale: 'en-US',
    isDefault: false
  }
};

// Formatos de precio para cada moneda
export const PRICE_FORMATS = {
  ARS: {
    locale: 'es-AR',
    currency: 'ARS',
    symbol: '$',
    options: {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }
  },
  USD: {
    locale: 'en-US',
    currency: 'USD',
    symbol: '$',
    options: {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  }
};

// Tasas de conversión (deberían actualizarse desde una API en producción)
export const EXCHANGE_RATES = {
  USD_TO_ARS: 900, // 1 USD = 900 ARS (actualizar con API)
  ARS_TO_USD: 1/900 // 1 ARS = 0.00111 USD
};

// Comisiones de plataforma y proveedores de pago
export const PAYMENT_FEES = {
  PLATFORM: 0.05, // 5% comisión de la plataforma
  MERCADOPAGO: 0.07, // 7% comisión de MercadoPago
  PAYPAL: 0.0349, // 3.49% comisión de PayPal
  STRIPE: 0.029 // 2.9% comisión de Stripe
};

// Proveedores de pago disponibles
export const PAYMENT_PROVIDERS = {
  MERCADOPAGO: 'mercadopago',
  PAYPAL: 'paypal',
  STRIPE: 'stripe'
};

// Configuración de proveedores de pago por moneda
export const PROVIDER_CURRENCY_CONFIG = {
  ARS: [
    { provider: PAYMENT_PROVIDERS.MERCADOPAGO, enabled: true }
  ],
  USD: [
    { provider: PAYMENT_PROVIDERS.PAYPAL, enabled: true }
  ]
};

// Descripciones de pagos
export const PAYMENT_DESCRIPTIONS = {
  BALANCE_RECHARGE: 'Recarga de saldo',
  MEMBERSHIP_PAYMENT: 'Pago de membresía',
  SERVICE_PAYMENT: 'Pago de servicio',
  COMMISSION_PAYMENT: 'Pago de comisión',
  WITHDRAWAL: 'Retiro de fondos',
  SUBSCRIPTION_PAYMENT: 'Pago de suscripción'
};

// Mensajes de error relacionados con pagos
export const PAYMENT_ERROR_MESSAGES = {
  VALIDATION_ERROR: 'Por favor, ingresa un monto válido mayor a 0.',
  UNAUTHORIZED: 'No estás autenticado. Por favor, inicia sesión nuevamente.',
  GENERIC_ERROR: 'Error al procesar el pago. Inténtalo de nuevo más tarde.',
  CONNECTION_ERROR: 'Error de conexión. Verifica tu conexión a internet e inténtalo de nuevo.',
  PAYMENT_CREATION_ERROR: 'Error al crear el pago. Inténtalo de nuevo más tarde.',
  PAYMENT_PROVIDER_ERROR: 'Error con el proveedor de pagos. Inténtalo de nuevo más tarde.',
  TERMS_ERROR: 'Debes aceptar los términos y condiciones para continuar.',
  CURRENCY_MISMATCH: 'La moneda seleccionada no es compatible con este método de pago.'
}; 