/**
 * Servicio para manejar las billeteras de diferentes monedas
 */

import { db } from '../../firebaseConfig';
import { 
  doc, 
  getDoc, 
  updateDoc, 
  setDoc, 
  collection, 
  addDoc, 
  serverTimestamp, 
  runTransaction,
  writeBatch
} from 'firebase/firestore';
import { WALLET_TYPES, CONVERSION_RATES, CURRENCY_CONVERSION } from '../../components/utils/constants';
import { toast } from 'react-toastify';

/**
 * Inicializa las billeteras para un usuario
 * @param {string} userId - ID del usuario
 * @returns {Promise<Object>} - Objeto con las billeteras creadas
 */
export const initializeWallets = async (userId) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    
    if (!userDoc.exists()) {
      console.error('Usuario no encontrado');
      return false;
    }
    
    const userData = userDoc.data();
    
    // Si ya existen las billeteras, no hacer nada
    if (userData.wallets) {
      return true;
    }
    
    // Inicializar las billeteras
    const wallets = {
      [WALLET_TYPES.ARS]: { balance: 0 },
      [WALLET_TYPES.USD]: { balance: 0 }
    };
    
    // Actualizar el documento del usuario
    await updateDoc(userDocRef, { wallets });
    
    return true;
  } catch (error) {
    console.error('Error al inicializar billeteras:', error);
    return false;
  }
};

/**
 * Obtiene las billeteras del usuario, creando las que no existan
 * @param {string} userId - ID del usuario
 * @returns {Promise<Object>} - Objeto con las billeteras
 */
export const getWallets = async (userId) => {
  try {
    // Referencia al documento del usuario en la colección wallets
    const userWalletsRef = doc(db, 'wallets', userId);
    const walletsDoc = await getDoc(userWalletsRef);
    
    // Si no existen billeteras, crearlas con saldo inicial 0
    if (!walletsDoc.exists()) {
      const initialWallets = {
        [WALLET_TYPES.ARS]: {
          balance: 0,
          updatedAt: serverTimestamp()
        },
        [WALLET_TYPES.USD]: {
          balance: 0,
          updatedAt: serverTimestamp()
        }
      };
      
      await setDoc(userWalletsRef, initialWallets);
      return initialWallets;
    }
    
    return walletsDoc.data();
  } catch (error) {
    console.error('Error al obtener billeteras:', error);
    throw new Error('No se pudieron cargar las billeteras');
  }
};

/**
 * Actualiza el saldo de una billetera
 * @param {string} userId - ID del usuario
 * @param {string} walletType - Tipo de billetera (ARS o USD)
 * @param {number} amount - Monto a sumar (positivo) o restar (negativo)
 * @param {string} description - Descripción de la transacción
 * @returns {Promise<Object>} - Billetera actualizada
 */
export const updateWalletBalance = async (userId, walletType, amount, description) => {
  try {
    // Validar que sea un tipo de billetera válido
    if (!Object.values(WALLET_TYPES).includes(walletType)) {
      throw new Error('Tipo de billetera inválido');
    }
    
    // Usar una transacción para garantizar consistencia
    return await runTransaction(db, async (transaction) => {
      // Referencia al documento del usuario en la colección wallets
      const userWalletsRef = doc(db, 'wallets', userId);
      const walletsDoc = await transaction.get(userWalletsRef);
      
      // Si no existen billeteras, crearlas primero
      if (!walletsDoc.exists()) {
        throw new Error('Las billeteras no existen');
      }
      
      const currentData = walletsDoc.data();
      const currentBalance = currentData[walletType]?.balance || 0;
      
      // Calcular nuevo saldo
      const newBalance = currentBalance + amount;
      
      // Validar que no quede saldo negativo
      if (newBalance < 0) {
        throw new Error('Saldo insuficiente para esta operación');
      }
      
      // Actualizar saldo
      transaction.update(userWalletsRef, {
        [`${walletType}.balance`]: newBalance,
        [`${walletType}.updatedAt`]: serverTimestamp()
      });
      
      // Registrar la transacción
      const transactionRef = collection(db, 'walletTransactions');
      transaction.set(doc(transactionRef), {
        userId,
        walletType,
        amount,
        balanceBefore: currentBalance,
        balanceAfter: newBalance,
        description,
        timestamp: serverTimestamp()
      });
      
      return {
        ...currentData,
        [walletType]: {
          ...currentData[walletType],
          balance: newBalance
        }
      };
    });
  } catch (error) {
    console.error('Error al actualizar billetera:', error);
    throw new Error(error.message || 'Error al actualizar saldo de la billetera');
  }
};

/**
 * Convierte fondos entre billeteras (ARS a USD o viceversa)
 * @param {string} userId - ID del usuario
 * @param {string} fromWallet - Tipo de billetera origen
 * @param {string} toWallet - Tipo de billetera destino
 * @param {number} amount - Monto a convertir
 * @returns {Promise<Object>} - Objeto con las billeteras actualizadas y monto convertido
 */
export const convertBetweenWallets = async (userId, fromWallet, toWallet, amount) => {
  try {
    // Validar tipos de billeteras
    if (
      !Object.values(WALLET_TYPES).includes(fromWallet) || 
      !Object.values(WALLET_TYPES).includes(toWallet)
    ) {
      throw new Error('Tipo de billetera inválido');
    }
    
    // Validar que las billeteras sean diferentes
    if (fromWallet === toWallet) {
      throw new Error('Las billeteras de origen y destino deben ser diferentes');
    }
    
    // Validar monto
    if (amount <= 0) {
      throw new Error('El monto debe ser mayor a cero');
    }
    
    // Calcular tasa de conversión
    let conversionRate;
    if (fromWallet === WALLET_TYPES.ARS && toWallet === WALLET_TYPES.USD) {
      conversionRate = CURRENCY_CONVERSION.ARS_TO_USD;
    } else {
      conversionRate = CURRENCY_CONVERSION.USD_TO_ARS;
    }
    
    // Calcular monto convertido
    const convertedAmount = amount * conversionRate;
    
    // Usar una transacción para garantizar consistencia
    return await runTransaction(db, async (transaction) => {
      // Referencia al documento del usuario en la colección wallets
      const userWalletsRef = doc(db, 'wallets', userId);
      const walletsDoc = await transaction.get(userWalletsRef);
      
      // Si no existen billeteras, lanzar error
      if (!walletsDoc.exists()) {
        throw new Error('Las billeteras no existen');
      }
      
      const currentData = walletsDoc.data();
      const fromBalance = currentData[fromWallet]?.balance || 0;
      const toBalance = currentData[toWallet]?.balance || 0;
      
      // Validar saldo suficiente
      if (fromBalance < amount) {
        throw new Error('Saldo insuficiente para esta conversión');
      }
      
      // Actualizar saldos
      transaction.update(userWalletsRef, {
        [`${fromWallet}.balance`]: fromBalance - amount,
        [`${fromWallet}.updatedAt`]: serverTimestamp(),
        [`${toWallet}.balance`]: toBalance + convertedAmount,
        [`${toWallet}.updatedAt`]: serverTimestamp()
      });
      
      // Registrar la transacción
      const transactionRef = collection(db, 'walletTransactions');
      transaction.set(doc(transactionRef), {
        userId,
        fromWallet,
        toWallet,
        amount,
        convertedAmount,
        conversionRate,
        timestamp: serverTimestamp(),
        description: `Conversión de ${amount} ${fromWallet.toUpperCase()} a ${convertedAmount.toFixed(2)} ${toWallet.toUpperCase()}`
      });
      
      // Devolver billeteras actualizadas
      return {
        fromWallet: {
          ...currentData[fromWallet],
          balance: fromBalance - amount
        },
        toWallet: {
          ...currentData[toWallet],
          balance: toBalance + convertedAmount
        },
        convertedAmount
      };
    });
  } catch (error) {
    console.error('Error al convertir entre billeteras:', error);
    throw new Error(error.message || 'Error al convertir fondos entre billeteras');
  }
};

export default {
  initializeWallets,
  getWallets,
  updateWalletBalance,
  convertBetweenWallets
}; 