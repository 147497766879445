import React, { useState } from 'react';
import styles from '../Styles/Layout.module.css';
import Header from '../components/Header/Header';
import AddBalance from '../components/Header/addBalance';
import Notifications from './Notifications/Notifications';

const Layout = ({ children }) => {
  const [activeModal, setActiveModal] = useState(null);
  const [notificationsVisible, setNotificationsVisible] = useState(false);

  return (
    <div className={styles.appContainer}>
      <Header 
        openModal={setActiveModal} 
        openNotifications={() => setNotificationsVisible(true)} 
      />
      
      <main className={`${styles.mainContent} ${activeModal ? styles.blurBackground : ''}`}>
        {children}
      </main>

      {activeModal === 'addBalance' && (
        <AddBalance onClose={() => setActiveModal(null)} />
      )}
      
      {notificationsVisible && (
        <Notifications onClose={() => setNotificationsVisible(false)} />
      )}
    </div>
  );
};

export default Layout;
