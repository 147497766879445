// src/components/darkMode/ThemeToggle.jsx
import React, { useEffect } from 'react';
import { useThemeContext } from '../../context/ThemeContext';
import styles from './ThemeToggle.module.css';
import { FaSun, FaMoon } from 'react-icons/fa';

const ThemeToggle = () => {
  const { darkMode, toggleDarkMode } = useThemeContext();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 't' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        toggleDarkMode();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [toggleDarkMode]);

  return (
    <button
      onClick={toggleDarkMode}
      className={`${styles.themeToggle} ${styles[darkMode ? 'dark' : 'light']}`}
      aria-label={`Cambiar a modo ${darkMode ? 'claro' : 'oscuro'}`}
      title={`Cambiar a modo ${darkMode ? 'claro' : 'oscuro'} (Ctrl+T)`}
    >
      {darkMode ? <FaSun /> : <FaMoon />}
    </button>
  );
};

export default ThemeToggle;
