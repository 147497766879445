// src/context/SubscriptionContext.js
import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { db, auth } from '../firebaseConfig';
import { collection, query, onSnapshot, doc, updateDoc, getDocs, where } from 'firebase/firestore';
import { toast } from 'react-toastify';

export const SubscriptionContext = createContext();

export const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (!context) throw new Error('useSubscription debe usarse dentro de SubscriptionProvider');
  return context;
};

export const SubscriptionProvider = ({ children }) => {
  const [content, setContent] = useState([]);
  const [plansCache, setPlansCache] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Obtenemos el userId del usuario actual de Firebase Auth
  const userId = auth.currentUser?.uid;

  // Obtener suscripciones - Implementando directamente sin SubscriptionService
  useEffect(() => {
    const fetchSubscriptions = async () => {
      if (!userId) return;
      try {
        setIsLoading(true);
        // Implementación directa para obtener suscripciones
        const subsQuery = query(
          collection(db, 'subscriptions'), 
          where('subscriberId', '==', userId)
        );
        const subsSnapshot = await getDocs(subsQuery);
        const subsData = subsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSubscriptions(subsData);
      } catch (err) {
        setError(err.message);
        toast.error('Error al cargar suscripciones');
      } finally {
        setIsLoading(false);
      }
    };
    fetchSubscriptions();
  }, [userId]);

  // Escuchar contenido exclusivo (galería)
  useEffect(() => {
    if (!userId) {
      setIsLoading(false);
      return;
    }
    const q = query(collection(db, `users/${userId}/subsContent`));
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const newContent = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setContent(newContent);
        setIsLoading(false);
      },
      (err) => {
        console.error('Error en snapshot:', err);
        setError('Error al cargar contenido');
        setIsLoading(false); // Se marca la carga como finalizada incluso si hay error
      }
    );
    return unsubscribe;
  }, [userId]);

  // Función para actualizar planes y galerías relacionadas
  const updateGalleriesAndPlans = useCallback(async (updatedPlans) => {
    try {
      if (!userId) throw new Error('Usuario no autenticado');
      
      await updateDoc(doc(db, 'users', userId), { 
        subscriptionPlans: updatedPlans 
      });

      // Actualizar galerías relacionadas: filtrar las galerías que tengan planes que ya no existen
      const batch = content
        .filter(gallery => 
          gallery.assignedPlans.some(planId => 
            !updatedPlans.some(p => p.id === planId)
          )
        )
        .map(gallery => {
          const galleryRef = doc(db, `users/${userId}/subsContent`, gallery.id);
          return updateDoc(galleryRef, {
            assignedPlans: gallery.assignedPlans.filter(planId => 
              updatedPlans.some(p => p.id === planId)
            )
          });
        });

      await Promise.all(batch);
      setPlansCache(updatedPlans);
      toast.success('Actualización exitosa');
    } catch (err) {
      toast.error('Error al actualizar');
      console.error('Error en updateGalleriesAndPlans:', err);
    }
  }, [content, userId]);

  const value = {
    content,
    subscriptions,
    plansCache,
    isLoading,
    error,
    updateGalleriesAndPlans
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};

SubscriptionProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SubscriptionProvider;
