// src/context/UserContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { getAuth, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebaseConfig';

export const UserContext = createContext();

export const useAuth = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useAuth must be used within a UserProvider');
  }
  return context;
};

export const UserProvider = ({ children }) => {
    // Initialize authentication and state
    const auth = getAuth();
    const [user, setUser] = useState(null);
    const [authToken, setAuthToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Helper function to check premium status
    const checkPremiumStatus = (userData) => {
        return userData.role === "premium" || userData.role === "admin";
    };

    useEffect(() => {
        let unsubscribeUser = null;

        // Listen for authentication state changes
        const unsubscribeAuth = onAuthStateChanged(auth, async (currentUser) => {
            setLoading(true);
            setError(null);

            if (currentUser) {
                try {
                    // Get the authentication token
                    const token = await currentUser.getIdToken();
                    setAuthToken(token);

                    // Check if user is in registration process
                    const isRegistering = sessionStorage.getItem('registrationComplete');
                    if (isRegistering) {
                        setUser({
                            uid: currentUser.uid,
                            email: currentUser.email,
                            displayName: currentUser.displayName || currentUser.email,
                            verified: false,
                            role: 'free'
                        });
                        setLoading(false);
                        return;
                    }

                    // Set up real-time listener for user data
                    const userRef = doc(db, 'users', currentUser.uid);
                    unsubscribeUser = onSnapshot(userRef, 
                        (docSnapshot) => {
                            if (docSnapshot.exists()) {
                                const userData = docSnapshot.data();
                                const userWithId = {
                                    ...userData,
                                    uid: currentUser.uid,
                                    email: currentUser.email,
                                    displayName: userData.displayName || currentUser.displayName || currentUser.email,
                                    role: userData.role || 'free',
                                    isPremium: checkPremiumStatus(userData),
                                    isAdmin: userData.role === 'admin',
                                    hasAdminAccess: userData.role === 'admin',
                                    membershipData: userData.membershipData || null,
                                    creatorSettings: userData.creatorSettings || null
                                };
                                setUser(userWithId);
                            } else {
                                setUser(null);
                            }
                            setLoading(false);
                        },
                        (error) => {
                            console.error('Error in snapshot:', error);
                            // Implement fallback for permission errors
                            if (error.code === 'permission-denied') {
                                // Attempt a one-time fetch instead of real-time updates
                                console.log('Attempting fallback data fetch...');
                            }
                            setError(error);
                            setLoading(false);
                        }
                    );
                } catch (error) {
                    console.error('Error:', error);
                    setUser(null);
                    setError(error);
                    setLoading(false);
                }
            } else {
                setUser(null);
                setAuthToken(null);
                setLoading(false);
            }
        });

        // Listen for token changes
        const unsubscribeToken = onIdTokenChanged(auth, async (currentUser) => {
            if (currentUser) {
                try {
                    const token = await currentUser.getIdToken(true);
                    setAuthToken(token);
                } catch (error) {
                    console.error('Error al renovar el token:', error);
                    setAuthToken(null);
                    setError(error);
                }
            } else {
                setAuthToken(null);
            }
        });

        // Cleanup function
        return () => {
            if (unsubscribeUser) unsubscribeUser();
            unsubscribeAuth();
            unsubscribeToken();
        };
    }, [auth]);

    // Context value with all necessary user data and utilities
    const value = {
        user,
        authToken,
        loading,
        error,
        setUser,
        isAuthenticated: !!user,
        isPremiumOrAdmin: user ? checkPremiumStatus(user) : false,
        isAdmin: user?.role === 'admin',
        hasAccessToFeature: (feature) => {
            if (!user) return false;
            if (user.role === 'admin') return true;
            if (feature === 'premium' && user.role === 'premium') return true;
            return false;
        }
    };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;