import React, { createContext, useState, useContext, useEffect } from 'react';

// Crear el contexto de tema
export const ThemeContext = createContext();

// Hook personalizado para facilitar el uso del contexto
export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext debe usarse dentro de un ThemeProvider');
  }
  return context;
};

// Provider del contexto del tema
export const ThemeProvider = ({ children }) => {
  // Obtener el modo inicial del localStorage o usar el modo claro por defecto
  const getInitialMode = () => {
    if (typeof window !== 'undefined' && window.localStorage) {
      const storedPrefs = window.localStorage.getItem('darkMode');
      if (storedPrefs !== null) {
        return JSON.parse(storedPrefs);
      }
      // Si el usuario prefiere el modo oscuro en su sistema
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return true;
      }
    }
    // Valor predeterminado: modo claro
    return false;
  };

  // Estado para el modo oscuro
  const [darkMode, setDarkMode] = useState(getInitialMode);

  // Función para alternar entre modo claro y oscuro
  const toggleDarkMode = () => {
    setDarkMode(prevMode => !prevMode);
  };

  // Efecto para guardar el estado en localStorage
  useEffect(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      window.localStorage.setItem('darkMode', JSON.stringify(darkMode));
    }
    
    // Aplicar la clase o atributo al documento según el modo
    if (darkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
      document.body.classList.add('dark-mode');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);

  // Proporcionar el contexto a los componentes hijos
  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
}; 