// src/components/darkMode/ThemeProvider.jsx
import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';

// Este hook sirve como adaptador para componentes antiguos que todavía utilizan useTheme
export const useTheme = () => {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  if (!darkMode && toggleDarkMode === undefined) {
    throw new Error("useTheme debe usarse dentro de ThemeProvider");
  }
  // Adaptamos la nueva API de darkMode a la antigua API de theme
  return {
    theme: darkMode ? 'dark' : 'light',
    toggleTheme: toggleDarkMode,
  };
};

// Este componente sirve como adaptador para mantener compatibilidad con el código existente
export const ThemeProvider = ({ children }) => {
  // Ya no necesitamos hacer nada aquí, puesto que todo el estado se gestiona en el nuevo ThemeContext
  return children;
};
