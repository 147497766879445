import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth } from '../firebaseConfig';
import { toast } from 'react-toastify';
import { UserContext } from './UserContext';
import PropTypes from 'prop-types';

export const MembershipContext = createContext();

export const MembershipProvider = ({ children }) => {
  const userContext = useContext(UserContext);
  const user = userContext?.user;
  const [membershipStatus, setMembershipStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Limpiar estado cuando el usuario cambie
  useEffect(() => {
    if (!user) {
      setMembershipStatus(null);
      setIsLoading(false);
      return;
    }
  }, [user]);

  useEffect(() => {
    const fetchMembership = async (currentUser) => {
      try {
        if (!currentUser) {
          setMembershipStatus(null);
          setIsLoading(false);
          return;
        }

        // Si es admin, establecer estado de membresía automáticamente
        if (user?.role === 'admin') {
          setMembershipStatus({
            type: 'admin',
            status: 'active',
            permanent: true
          });
          setIsLoading(false);
          return;
        }

        const token = await currentUser.getIdToken(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/membership/status`,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );

        if (!response.ok) {
          throw new Error(`Error de red: ${response.status}`);
        }

        const data = await response.json();
        
        if (data.success) {
          setMembershipStatus(data.membership);
        } else {
          setError(data.message || 'Error al obtener el estado de membresía');
          toast.error('Error al obtener el estado de membresía');
        }
      } catch (err) {
        console.error('Error details:', err);
        setError(err.message);
        toast.error('Error al conectar con el servidor');
      } finally {
        setIsLoading(false);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setIsLoading(true);
      fetchMembership(currentUser);
    });

    return () => unsubscribe();
  }, [user?.role]);

  const isUserPremium = () => {
    if (!user) return false;
    if (user.role === 'admin') return true;
    return user.role === 'premium';
  };

  const retryFetch = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    setIsLoading(true);
    setError(null);
    
    try {
      // Si es admin, no necesita hacer fetch
      if (user?.role === 'admin') {
        setMembershipStatus({
          type: 'admin',
          status: 'active',
          permanent: true
        });
        return;
      }

      const token = await currentUser.getIdToken(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/membership/status`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw new Error(`Error de red: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setMembershipStatus(data.membership);
        toast.success('Estado de membresía actualizado');
      } else {
        throw new Error(data.message || 'Error al actualizar el estado de membresía');
      }
    } catch (err) {
      setError(err.message);
      toast.error('Error al actualizar el estado de membresía');
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    membershipStatus,
    setMembershipStatus,
    isLoading,
    error,
    retryFetch,
    isActive: membershipStatus?.status === 'active' || user?.role === 'admin',
    isPremium: isUserPremium(),
    isAdmin: user?.role === 'admin',
    userRole: user?.role || 'free'
  };

  return (
    <MembershipContext.Provider value={value}>
      {children}
    </MembershipContext.Provider>
  );
};

MembershipProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useMembershipContext = () => {
  const context = useContext(MembershipContext);
  if (!context) {
    throw new Error('useMembershipContext debe ser usado dentro de un MembershipProvider');
  }
  return context;
};