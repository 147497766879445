// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAuth, signOut, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

// Tu configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDA69pFhhtib00x_FdWx-Y4_IVvrjYG_ag",
  authDomain: "illustra-6ca8a.firebaseapp.com",
  databaseURL: "https://illustra-6ca8a-default-rtdb.firebaseio.com",
  projectId: "illustra-6ca8a",
  storageBucket: "illustra-6ca8a.appspot.com",
  messagingSenderId: "643320223920",
  appId: "1:643320223920:web:d39c2493da8472ab934375",
  measurementId: "G-69S0CBVXMH"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);

// Inicializar Auth
export const auth = getAuth(app);

// Establecer persistencia de sesión
setPersistence(auth, browserLocalPersistence)
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

// Función para cerrar sesión
const logout = async () => {
  try {
    await signOut(auth);
    // Limpiar localStorage al cerrar sesión
    localStorage.removeItem('userData');
  } catch (error) {
    console.error("Error during logout:", error);
    throw error;
  }
};

// Inicializar Firestore
export const db = getFirestore(app);

// Inicializar Storage
export const storage = getStorage(app);

// Función para verificar si las reglas de Storage permiten archivos de video
export const checkStorageRules = async () => {
  console.log("Comprobando configuración de Firebase Storage...");
  try {
    // Crear un archivo de prueba pequeño (1KB) para verificar permisos
    const testBlob = new Blob([new ArrayBuffer(1024)], {type: 'video/mp4'});
    const testRef = ref(storage, 'test-permissions/test-video.mp4');
    
    // Intentar subir el archivo de prueba
    try {
      const uploadTask = uploadBytesResumable(testRef, testBlob);
      
      // Si no hay error inmediato, cancelar la subida
      uploadTask.cancel();
      console.log("Las reglas de Storage permiten subir videos");
      return true;
    } catch (uploadError) {
      console.error("Las reglas de Storage no permiten subir videos:", uploadError);
      console.warn("Es necesario actualizar las reglas de Storage para permitir archivos de video");
      return false;
    }
  } catch (error) {
    console.error("Error al verificar las reglas de Storage:", error);
    return false;
  }
};

// Inicializar Functions
const functions = getFunctions(app);

// Si estás en desarrollo, conecta con el emulador
if (process.env.NODE_ENV === 'development') {
  // Ajusta el puerto si tu emulador de Functions usa otro
  functions.functionsEmulatorHost = 'localhost';
  functions.functionsEmulatorPort = 5001;
}

export { functions, httpsCallable };
export { logout };
