// src/App.js
"use client";

import React, { useContext, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useParams, useNavigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { UserProvider, UserContext } from "./context/UserContext";
import { MembershipProvider } from "./context/MembershipContext";
import { BlockedDomainsProvider } from "./context/BlockedDomainsContext";
import { SubscriptionProvider } from "./context/SubscriptionContext";
import { UserCacheProvider } from "./context/UserCacheContext";
import LoadingScreen from "./components/LoadingSpinner/LoadingSpinner";
import { ToastContainer } from "react-toastify";
import Layout from "./components/Layout";
import ProtectedRoute from "./components/ProtectedRoute";
import ThemeToggle from "./components/darkMode/ThemeToggle";
import { ThemeProvider, ThemeContext } from "./context/ThemeContext";

// Importa el NotificationsProvider que creaste
import { NotificationsProvider } from "./components/Notifications/NotificationsContext";

import "./Styles/variables.css";
import "./Styles/global.css";
import "react-toastify/dist/ReactToastify.css";

// Lazy imports
const Home = React.lazy(() => import("./components/HomeComponents/home"));
const Login = React.lazy(() => import("./components/LoginRegister&Settings/login.js"));
const Register = React.lazy(() => import("./components/LoginRegister&Settings/register.js"));
const Profile = React.lazy(() => import("./components/perfil/Perfil.jsx"));
const Configuration = React.lazy(() => import("./components/User&AdminOptions/Configuration/Configuration"));
const PostPage = React.lazy(() => import("./components/Feed/PostPage.jsx"));
const MembershipBenefits = React.lazy(() => import("./components/membership/MembershipBenefits"));
const PremiumDashboard = React.lazy(() => import("./components/membership/PremiumDashboard/PremiumDashboard"));
const UpgradePage = React.lazy(() => import("./components/membership/Upgrade/UpgradePage"));
const MembershipSuccess = React.lazy(() => import("./components/membership/PaymentStatus/Success"));
const MembershipFailure = React.lazy(() => import("./components/membership/PaymentStatus/Failure"));
const MembershipPending = React.lazy(() => import("./components/membership/PaymentStatus/Pending"));
const SuscripcionPage = React.lazy(() => import("./components/membership/SubscriptionPage"));
const PaymentSuccessComponent = React.lazy(() => import("./components/MercadoPago/PaymentSuccess"));
const PaymentFailureComponent = React.lazy(() => import("./components/MercadoPago/PaymentFailure"));
const PaymentPendingComponent = React.lazy(() => import("./components/MercadoPago/PaymentPending"));
const CallbackPage = React.lazy(() => import("./components/MercadoPago/CallbackPage"));
const Explore = React.lazy(() => import("./components/HomeComponents/Explore/ExploreContent"));
const ExplorePosts = React.lazy(() => import("./components/HomeComponents/Explore/ExplorePosts"));
const ExploreServices = React.lazy(() => import("./components/ExploreServices/exploreServices"));
const ExploreUsers = React.lazy(() => import("./components/HomeComponents/Explore/ExploreUsers"));
const LearnMore = React.lazy(() => import("./components/HomeComponents/LearnMore"));
const ServiceRequest = React.lazy(() => import("./components/Services/ServiceRequest"));
const ServiceDetailsUser = React.lazy(() => import("./components/Services/ServiceDetailsUser/ServiceDetailsUser"));
const ServiceDetailsWorker = React.lazy(() => import("./components/Services/ServiceDetailsWorker/ServiceDetailsWorker"));
const UserDashboard = React.lazy(() => import("./components/User&AdminOptions/a_userDashboard/UserDashboard"));
const AdminDashboard = React.lazy(() => import("./components/User&AdminOptions/a_adminDashboard/AdminDashboard.jsx"));
const Workbench = React.lazy(() => import("./components/workbench/Workbench"));
const ForgotPassword = React.lazy(() => import("./components/LoginRegister&Settings/forgotPassword"));
const WaitingVerification = React.lazy(() => import("./components/LoginRegister&Settings/WaitingVerification"));
const Notifications = React.lazy(() => import("./components/Notifications/Notifications"));
const TermsAndConditions = React.lazy(() => import("./components/HomeComponents/Legal/TermsAndConditions"));
const Privacy = React.lazy(() => import("./components/HomeComponents/Legal/Privacy"));
const Cookies = React.lazy(() => import("./components/HomeComponents/Legal/Cookies"));
const Licenses = React.lazy(() => import("./components/HomeComponents/Legal/Licenses"));
const FAQ = React.lazy(() => import("./components/HomeComponents/Legal/FAQ"));
const Help = React.lazy(() => import("./components/HomeComponents/Legal/Help"));
const Contact = React.lazy(() => import("./components/HomeComponents/Legal/Contact"));
const EditProfile = React.lazy(() => import("./components/perfil/edit/EditProfile.jsx"));
const PostView = React.lazy(() => import("./components/Feed/PostPage.jsx"));
const GaleriaViewer = React.lazy(() => import("./components/perfil/galerias/GaleriaViewer.jsx"));
const GaleriaForm = React.lazy(() => import("./components/perfil/galerias/GaleriaForm"));
const CreateService = React.lazy(() => import("./components/Services/CreateService/CreateService"));

// Página de mantenimiento
const MaintenancePage = () => {
  const { darkMode } = useContext(ThemeContext);
  
  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      minHeight: '100vh',
      backgroundColor: darkMode ? '#121212' : '#f5f5f5',
      color: darkMode ? '#ffffff' : '#333333',
      padding: '20px',
      textAlign: 'center'
    }}>
      <img 
        src="/logo512.png" 
        alt="Illustra Logo" 
        style={{ width: '120px', marginBottom: '30px' }}
      />
      <h1 style={{ fontSize: '2.5rem', marginBottom: '20px' }}>Sitio en Mantenimiento</h1>
      <div style={{ 
        maxWidth: '600px', 
        backgroundColor: darkMode ? '#1e1e1e' : '#ffffff',
        borderRadius: '8px',
        padding: '30px',
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
      }}>
        <p style={{ fontSize: '1.2rem', marginBottom: '20px', lineHeight: '1.6' }}>
          Estamos realizando mejoras en la plataforma para brindarte una mejor experiencia.
        </p>
        <p style={{ fontSize: '1.1rem', marginBottom: '30px', lineHeight: '1.6' }}>
          Por favor, vuelve más tarde. ¡Gracias por tu paciencia!
        </p>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          marginTop: '20px',
          gap: '10px' 
        }}>
          <span style={{ fontSize: '2rem', marginRight: '15px' }}>🔧</span>
          <span style={{ fontSize: '2rem', marginRight: '15px' }}>🚧</span>
          <span style={{ fontSize: '2rem' }}>🔨</span>
        </div>
      </div>
      <div style={{ marginTop: '30px' }}>
        <a 
          href="/login"
          style={{
            padding: '10px 20px',
            background: 'var(--primary-color, #8445ff)',
            color: 'white',
            borderRadius: '5px',
            textDecoration: 'none',
            display: 'inline-block',
            fontWeight: 'bold'
          }}
        >
          Iniciar Sesión
        </a>
      </div>
    </div>
  );
};

// Componente para redirigir a la ruta de perfil
const ProfileRedirect = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  
  useEffect(() => {
    navigate(`/perfil/${userId}`, { replace: true });
  }, [userId, navigate]);
  
  return <LoadingScreen />;
};

function AppContent() {
  const { loading, user, isAdmin } = useContext(UserContext);
  
  if (loading) return <LoadingScreen />;

  // Si el usuario no es admin y no es "admin", mostrar página de mantenimiento
  // excepto en rutas de autenticación
  const isAuthRoute = window.location.pathname.includes('/login') || 
                     window.location.pathname.includes('/register') || 
                     window.location.pathname.includes('/forgot-password');
  
  if (!isAuthRoute && (!user || (user && !isAdmin && user.username !== 'admin'))) {
    return <MaintenancePage />;
  }

  return (
    <Router>
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          {/* Auth Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/waiting-verification"
            element={
              <UserContext.Consumer>
                {({ user }) => (user ? <WaitingVerification /> : <Navigate to="/register" />)}
              </UserContext.Consumer>
            }
          />

          {/* Main Routes */}
          <Route path="/" element={<Layout><Home /></Layout>} />
          <Route path="/home" element={<Layout><Home /></Layout>} />
          <Route path="/profile/:userId" element={<ProfileRedirect />} />
          <Route path="/perfil/:userId" element={<Layout><Profile /></Layout>} />
          <Route path="/edit/profile/:userId" element={<Layout><EditProfile /></Layout>} />
          <Route path="/post/:postId" element={<Layout><PostPage /></Layout>} />

          {/* Rutas para galerías/colecciones */}
          <Route
            path="/colecciones/:galeriaId"
            element={
              <Layout><GaleriaViewer /></Layout>
            }
          />
          
          {/* Rutas para galerías */}
          <Route
            path="/galerias/nueva"
            element={
              <ProtectedRoute>
                <Layout><GaleriaForm /></Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/galerias/:galeriaId"
            element={
              <Layout><GaleriaViewer /></Layout>
            }
          />

          {/* MercadoPago Routes */}
          <Route path="/mercadopago/callback" element={<Layout><CallbackPage /></Layout>} />
          <Route path="/mercadopago/callback/success" element={<Layout><PaymentSuccessComponent /></Layout>} />
          <Route path="/mercadopago/callback/failure" element={<Layout><PaymentFailureComponent /></Layout>} />
          <Route path="/mercadopago/callback/pending" element={<Layout><PaymentPendingComponent /></Layout>} />

          {/* Subscription Routes */}
          <Route path="/suscripcion" element={<Layout><SuscripcionPage /></Layout>} />

          {/* Membership Routes */}
          <Route
            path="/membership/*"
            element={
              <ProtectedRoute>
                <Layout>
                  <Routes>
                    <Route path="/" element={<UpgradePage />} />
                    <Route path="benefits" element={<MembershipBenefits />} />
                    <Route path="premium" element={<PremiumDashboard />} />
                    <Route path="upgrade" element={<UpgradePage />} />
                    <Route path="success" element={<MembershipSuccess />} />
                    <Route path="failure" element={<MembershipFailure />} />
                    <Route path="pending" element={<MembershipPending />} />
                  </Routes>
                </Layout>
              </ProtectedRoute>
            }
          />

          {/* Explore Routes */}
          <Route path="/explore" element={<Layout><Explore /></Layout>} />
          <Route path="/explore-posts" element={<Layout><ExplorePosts /></Layout>} />
          <Route path="/explore-services" element={<Layout><ExploreServices /></Layout>} />
          <Route path="/explore-users" element={<Layout><ExploreUsers /></Layout>} />
          <Route path="/learn-more" element={<Layout><LearnMore /></Layout>} />

          {/* Protected Routes */}
          <Route
            path="/configuration"
            element={
              <ProtectedRoute>
                <Layout><Configuration /></Layout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/workbench"
            element={
              <ProtectedRoute>
                <Layout><Workbench /></Layout>
              </ProtectedRoute>
            }
          />

          {/* Service Routes */}
          <Route
            path="/service-request/:illustratorID/:serviceId"
            element={
              <ProtectedRoute>
                <Layout><ServiceRequest /></Layout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/servicios/nuevo"
            element={
              <ProtectedRoute>
                <Layout><CreateService /></Layout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/servicios/editar/:serviceId"
            element={
              <ProtectedRoute>
                <Layout><CreateService /></Layout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/service-details/:requestId"
            element={
              <ProtectedRoute>
                <Layout><ServiceDetailsUser /></Layout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/service-details/worker/:requestId/:clientId"
            element={
              <ProtectedRoute>
                <Layout><ServiceDetailsWorker /></Layout>
              </ProtectedRoute>
            }
          />

          {/* Dashboard Routes */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Layout><UserDashboard /></Layout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin-dashboard"
            element={
              <ProtectedRoute adminOnly>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/notifications"
            element={
              <ProtectedRoute>
                <Layout><Notifications /></Layout>
              </ProtectedRoute>
            }
          />

          {/* Legal Routes */}
          <Route path="/terms" element={<Layout><TermsAndConditions /></Layout>} />
          <Route path="/privacy" element={<Layout><Privacy /></Layout>} />
          <Route path="/privacy-policy" element={<Navigate to="/privacy" />} />
          <Route path="/cookies" element={<Layout><Cookies /></Layout>} />
          <Route path="/licenses" element={<Layout><Licenses /></Layout>} />
          <Route path="/faq" element={<Layout><FAQ /></Layout>} />
          <Route path="/help" element={<Layout><Help /></Layout>} />
          <Route path="/contact" element={<Layout><Contact /></Layout>} />

          {/* 404 - Ruta no encontrada */}
          <Route 
            path="*" 
            element={
              <Layout>
                <div style={{ 
                  padding: '50px 20px', 
                  textAlign: 'center', 
                  minHeight: '70vh',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <h1>404 - Página No Encontrada</h1>
                  <p>Lo sentimos, la página que buscas no existe.</p>
                  <button 
                    onClick={() => window.history.back()} 
                    style={{
                      marginTop: '20px',
                      padding: '10px 20px',
                      background: 'var(--primary-color)',
                      color: 'white',
                      border: 'none',
                      borderRadius: '5px',
                      cursor: 'pointer'
                    }}
                  >
                    Volver atrás
                  </button>
                </div>
              </Layout>
            }
          />
        </Routes>
        <ThemeToggle />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Suspense>
    </Router>
  );
}

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <UserProvider>
          <BlockedDomainsProvider>
            <MembershipProvider>
              <SubscriptionProvider>
                <UserCacheProvider>
                  {/* Se envuelve la aplicación con el NotificationsProvider */}
                  <NotificationsProvider>
                    <Suspense fallback={<LoadingScreen />}>
                      <AppContent />
                    </Suspense>
                  </NotificationsProvider>
                </UserCacheProvider>
              </SubscriptionProvider>
            </MembershipProvider>
          </BlockedDomainsProvider>
        </UserProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;