import { db } from '../../firebaseConfig';
import { 
  collection, 
  addDoc, 
  doc, 
  updateDoc, 
  getDoc, 
  query, 
  where, 
  orderBy, 
  getDocs,
  runTransaction 
} from 'firebase/firestore';
import { PAYMENT_PROVIDERS, PAYMENT_FEES } from './paymentService';

export const TRANSACTION_TYPES = {
  DEPOSIT: 'deposit',
  WITHDRAWAL: 'withdrawal',
  SERVICE: 'service',
  COMMISSION: 'commission',
  REFUND: 'refund',
  SERVICE_FEE: 'service_fee'
};

export const TRANSACTION_STATUS = {
  PENDING: 'pending',
  COMPLETED: 'completed',
  FAILED: 'failed',
  REFUNDED: 'refunded',
  CANCELLED: 'cancelled'
};

/**
 * Crea una nueva transacción y actualiza el balance del usuario
 */
export const createTransaction = async (data) => {
  try {
    return await runTransaction(db, async (transaction) => {
      // Obtener referencia del usuario
      const userRef = doc(db, 'users', data.userId);
      const userDoc = await transaction.get(userRef);
      
      if (!userDoc.exists()) {
        throw new Error('Usuario no encontrado');
      }

      const userData = userDoc.data();
      
      // Calcular comisiones
      const platformFee = data.amount * PAYMENT_FEES.PLATFORM;
      const providerFee = data.amount * (PAYMENT_FEES[data.provider?.toUpperCase()] || 0);
      const totalFees = platformFee + providerFee;

      // Preparar datos de la transacción
      const transactionData = {
        userId: data.userId,
        type: data.type,
        amount: data.amount,
        currency: 'ARS',
        status: TRANSACTION_STATUS.PENDING,
        provider: data.provider,
        description: data.description,
        timestamp: new Date().toISOString(),
        fees: {
          platform: platformFee,
          provider: providerFee,
          total: totalFees
        },
        metadata: data.metadata || {}
      };

      // Validar balance según el tipo de transacción
      let newBalance = userData.balance || 0;
      let newPendingBalance = userData.pendingBalance || 0;

      switch (data.type) {
        case TRANSACTION_TYPES.WITHDRAWAL:
          if (newBalance < data.amount) {
            throw new Error('Saldo insuficiente para realizar el retiro');
          }
          newBalance -= data.amount;
          newPendingBalance += data.amount;
          break;

        case TRANSACTION_TYPES.SERVICE:
          // Para servicios, el monto se mueve a pendingBalance
          newPendingBalance += data.amount;
          break;

        case TRANSACTION_TYPES.SERVICE_FEE:
          // Las comisiones se descuentan directamente
          if (newBalance < totalFees) {
            throw new Error('Saldo insuficiente para cubrir las comisiones');
          }
          newBalance -= totalFees;
          break;

        case TRANSACTION_TYPES.REFUND:
          // Los reembolsos se agregan directamente al balance
          newBalance += data.amount;
          break;
      }

      // Crear la transacción
      const transactionRef = doc(collection(db, 'transactions'));
      transaction.set(transactionRef, transactionData);

      // Actualizar balance del usuario
      transaction.update(userRef, {
        balance: newBalance,
        pendingBalance: newPendingBalance,
        lastUpdated: new Date().toISOString()
      });

      return {
        transactionId: transactionRef.id,
        ...transactionData,
        newBalance,
        newPendingBalance
      };
    });
  } catch (error) {
    console.error('Error al crear transacción:', error);
    throw error;
  }
};

/**
 * Actualiza el estado de una transacción
 */
export const updateTransactionStatus = async (transactionId, newStatus, metadata = {}) => {
  try {
    return await runTransaction(db, async (transaction) => {
      const transactionRef = doc(db, 'transactions', transactionId);
      const transactionDoc = await transaction.get(transactionRef);
      
      if (!transactionDoc.exists()) {
        throw new Error('Transacción no encontrada');
      }

      const transactionData = transactionDoc.data();
      const userRef = doc(db, 'users', transactionData.userId);
      const userDoc = await transaction.get(userRef);
      
      if (!userDoc.exists()) {
        throw new Error('Usuario no encontrado');
      }

      const userData = userDoc.data();
      let newBalance = userData.balance || 0;
      let newPendingBalance = userData.pendingBalance || 0;

      // Actualizar balances según el tipo de transacción y el nuevo estado
      if (newStatus === TRANSACTION_STATUS.COMPLETED) {
        switch (transactionData.type) {
          case TRANSACTION_TYPES.DEPOSIT:
            newBalance += (transactionData.amount - transactionData.fees.total);
            break;

          case TRANSACTION_TYPES.SERVICE:
            // Cuando se completa un servicio, se mueve de pending a balance
            newPendingBalance -= transactionData.amount;
            newBalance += (transactionData.amount - transactionData.fees.total);
            break;

          case TRANSACTION_TYPES.WITHDRAWAL:
            // Cuando se completa un retiro, se elimina de pending
            newPendingBalance -= transactionData.amount;
            break;
        }
      } else if (newStatus === TRANSACTION_STATUS.FAILED || newStatus === TRANSACTION_STATUS.CANCELLED) {
        // Revertir los cambios pendientes
        switch (transactionData.type) {
          case TRANSACTION_TYPES.WITHDRAWAL:
            newBalance += transactionData.amount;
            newPendingBalance -= transactionData.amount;
            break;

          case TRANSACTION_TYPES.SERVICE:
            newPendingBalance -= transactionData.amount;
            break;
        }
      }

      // Actualizar la transacción
      transaction.update(transactionRef, {
        status: newStatus,
        lastUpdated: new Date().toISOString(),
        ...metadata
      });

      // Actualizar el usuario
      transaction.update(userRef, {
        balance: newBalance,
        pendingBalance: newPendingBalance,
        lastUpdated: new Date().toISOString()
      });

      return {
        transactionId,
        status: newStatus,
        newBalance,
        newPendingBalance,
        ...metadata
      };
    });
  } catch (error) {
    console.error('Error al actualizar estado de transacción:', error);
    throw error;
  }
};

/**
 * Obtiene el historial de transacciones de un usuario
 */
export const getUserTransactions = async (userId, filters = {}) => {
  try {
    let q = query(
      collection(db, 'transactions'),
      where('userId', '==', userId),
      orderBy('timestamp', 'desc')
    );

    // Aplicar filtros adicionales
    if (filters.type) {
      q = query(q, where('type', '==', filters.type));
    }
    if (filters.status) {
      q = query(q, where('status', '==', filters.status));
    }
    if (filters.startDate) {
      q = query(q, where('timestamp', '>=', filters.startDate));
    }
    if (filters.endDate) {
      q = query(q, where('timestamp', '<=', filters.endDate));
    }

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error al obtener transacciones:', error);
    throw error;
  }
}; 