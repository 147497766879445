// src/components/Notifications/NotificationsContext.js
import React, { createContext, useContext, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import Notifications from './Notifications';
import { sendNotification } from './NotificationService';

const NotificationsContext = createContext();

export const useNotifications = () => useContext(NotificationsContext);

export const NotificationsProvider = ({ children }) => {
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  
  const toggleNotifications = () => {
    setIsNotificationsOpen(prev => !prev);
  };
  
  const closeNotifications = () => {
    setIsNotificationsOpen(false);
  };
  
  // Función para enviar notificaciones desde componentes
  const notify = async (notificationData) => {
    console.log("📣 Enviando notificación desde context:", notificationData);
    try {
      const notificationId = await sendNotification({
        recipientId: notificationData.recipientId,
        title: notificationData.title,
        message: notificationData.message,
        type: notificationData.type,
        extraData: notificationData.extraData || {}
      });
      console.log("✅ Notificación enviada con ID:", notificationId);
      return notificationId;
    } catch (error) {
      console.error("❌ Error enviando notificación:", error);
      throw error;
    }
  };
  
  return (
    <NotificationsContext.Provider
      value={{
        toggleNotifications,
        closeNotifications,
        isNotificationsOpen,
        notify
      }}
    >
      {children}
      <AnimatePresence>
        {isNotificationsOpen && (
          <Notifications
            onClose={closeNotifications}
          />
        )}
      </AnimatePresence>
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;