// src/components/LoadingSpinner/LoadingSpinner.jsx
import React from 'react';
import styles from './LoadingSpinner.module.css';

const LoadingSpinner = ({ size = 'medium', color = 'primary' }) => {
  const sizeClass = styles[size] || styles.medium;
  const colorClass = styles[color] || styles.primary;
  
  return (
    <div className={`${styles.spinnerContainer} ${sizeClass} ${colorClass}`}>
      <div className={styles.spinner}></div>
    </div>
  );
};

export default LoadingSpinner;