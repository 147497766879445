// src/services/notifications/notificationService.js
import { db } from '../../firebaseConfig';
import { collection, addDoc, serverTimestamp, doc, getDoc, setDoc } from 'firebase/firestore';

/**
 * Crea el objeto navigation basado en el tipo de notificación
 */
const createNavigationData = (type, extraData) => {
  switch(type) {
    case 'follow':
      return {
        type: 'profile',
        data: { userId: extraData.userId }
      };
    // Añade otros casos según sea necesario
    default:
      return {
        type: extraData.type || 'generic',
        data: extraData
      };
  }
};

/**
 * Envía una notificación al usuario destinatario.
 */
export const sendNotification = async ({
  recipientId,
  title,
  message,
  type,
  extraData = {},
}) => {
  console.log("📣 Enviando notificación:", { recipientId, title, type });
  
  try {
    // Verificar campos requeridos
    if (!recipientId) {
      throw new Error('ID del destinatario requerido para la notificación');
    }
    
    if (!title || !message || !type) {
      throw new Error('Campos título, mensaje y tipo requeridos para la notificación');
    }
    
    // Verificar si el usuario destinatario existe
    const recipientRef = doc(db, 'users', recipientId);
    const recipientDoc = await getDoc(recipientRef);
    
    if (!recipientDoc.exists()) {
      throw new Error(`Usuario destinatario ${recipientId} no encontrado`);
    }
    
    // Preparar datos para la navegación
    const navigationData = createNavigationData(type, extraData);
    
    // Estructura completa de la notificación
    const notificationData = {
      title,
      message,
      type,
      timestamp: serverTimestamp(),
      read: false,
      recipientId,
      extraData: {
        ...extraData,
        navigation: navigationData
      }
    };
    
    console.log("Guardando notificación en Firestore:", notificationData);
    
    // Guardar la notificación en Firestore
    const notificationsRef = collection(db, 'users', recipientId, 'Notifications');
    const newNotificationRef = doc(notificationsRef); // Genera un ID único
    await setDoc(newNotificationRef, notificationData);
    
    console.log(`✅ Notificación enviada a ${recipientId}: ${title}`, newNotificationRef.id);
    return newNotificationRef.id;
  } catch (error) {
    console.error('❌ Error enviando notificación:', error);
    throw error;
  }
};